.wrapper {
    background: #e5e5e5;
}

@media only screen and (max-width: 391px) {
    .wrapper {
        height: unset;
    }
}

.confirmPageWrapper {
    background: #e5e5e5;
    height: calc(100vh - 100px);
}

.container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 880px;
    height: 100%;
    padding-top: 20px;
}

.container > div {
    margin-top: unset;
}

@media only screen and (max-width: 768px) {
    .container {
        max-width: 582px;
    }
    .container > div {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 391px) {
    .container {
        max-width: unset;
        padding: 14px;
    }
}

.container section {
    display: flex;
    flex-direction: column;
    padding: 40px 0 120px 0;
    gap: 28px;
}

@media only screen and (max-width: 768px) {
    .container section {
        gap: 16px;
        padding: 20px 0 100px 0;
    }
}

.detailsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 48px;
    grid-row-gap: 0;
    margin: 0 20px;
}

@media only screen and (max-width: 768px) {
    .detailsContainer {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(1, 1fr);
    }
}

.detailsCard {
    background-color: white;
    display: flex;
    gap: 25px;
    padding: 21px;
    align-items: flex-start;
}

@media only screen and (max-width: 768px) {
    .detailsContainer {
        gap: 20px;
    }

    .detailsCard {
        padding: 12px;
    }
}

.detailsCard p {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    font-family: 'Open Sans', sans-serif;
}

.featureContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 0;
    margin: 0 20px;
    overflow: auto;
}

@media only screen and (max-width: 391px) {
    .featureContainer {
        overflow: auto;
    }
}

.infoContainer {
    display: flex;
    gap: 178px;
    padding: 20px;
    margin: 0 20px 20px;
    background-color: white;
}

@media only screen and (max-width: 768px) {
    .infoContainer img {
        display: none;
    }

    .infoContainer p {
        font-size: 16px;
    }
}

@media only screen and (max-width: 391px) {
    .infoContainer {
        padding: 12px;
    }
}

.infoContainer > div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0;
    text-align: left;
    font-family: 'Open Sans', sans-serif;
}

.footerContainer {
    position: fixed;
    width: 100%;
    bottom: 0;
    height: 80px;
    background-color: white;
}

.footerContent {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
}

@media only screen and (max-width: 391px) {
    .footerContent button {
        width: 100%;
    }
}

.notification {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 40px;
    padding: 20px;
}

@media only screen and (max-width: 391px) {
    .notification {
        flex-direction: column;
        border-left: 2px solid #ff6a13;
        align-items: flex-start;
        gap: 10px;
        padding: 0 10px 0 10px;
    }
}

.notification div {
    border-left: 2px solid #ff6a13;
    color: #ff6a13;
    font-size: 13px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0;
    padding-left: 20px;
    font-family: 'Gilroy Regular', sans-serif;
}

@media only screen and (max-width: 391px) {
    .notification div {
        border-left: unset;
        padding-left: unset;
    }
}

.notification p {
    font-size: 13px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0;
    font-family: 'Gilroy Regular', sans-serif;
}

@media only screen and (max-width: 391px) {
    .notification p {
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 400;
    }
}

.loaderContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 1000;
    overflow-y: auto;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.75);
}

/* ======================= Confirm card styles =================================== */

.cardContainer {
    display: flex;
    flex-direction: column;
    gap: 27px;
    background-color: white;
    width: 416px;
    align-self: center;
    padding: 20px;
}

@media only screen and (max-width: 391px) {
    .cardContainer {
        width: 100%;
    }
}

.cardContent {
    display: flex;
    flex-direction: column;
    gap: 11px;
}

.cardDivider {
    height: 1px;
    width: 100%;
    background-color: #13353f;
    opacity: 10%;
}

.cardConfirmInfo p {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 1px;
}

.cardConfirmInfo p mark {
    background-color: unset;
    color: #ff6a13;
}

.cardFooter {
    display: flex;
    justify-content: center;
}

.loader {
    width: 20px;
    height: 20px;
}
