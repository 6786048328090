.container {
    position: relative;
    width: 100%;
    display: flex;
    overflow: hidden;
}

.nav {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;
}

:global(.cashbackNotActivated) .nav {
    height: auto;
}

.navScroller {
    overflow-x: hidden;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-flex-item-align: center;
    align-self: center;
}

@media only screen and (max-width: 767px) {
    .navScroller {
        z-index: 0;
    }
}

.navList {
    display: flex;
    width: calc(100% - 310px);
    margin-bottom: 0;
    padding-left: 24px;
}

:global(.cashbackNotActivated) .navList {
    height: 75px;
}

@media only screen and (max-width: 1170px) {
    .navList {
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
    }

    @media (hover) {
        .navList::-webkit-scrollbar {
            background-color: #fff;
            width: 15px;
        }

        .navList::-webkit-scrollbar {
            height: 0;
        }

        .navList:hover::-webkit-scrollbar {
            height: 7px;
        }
    }

    .navList::-webkit-scrollbar-track,
    .navList::-webkit-scrollbar-track:hover {
        background-color: #fff;
    }

    .navList::-webkit-scrollbar-thumb {
        background-color: #babac0;
        border-radius: 16px;
    }

    .navList::-webkit-scrollbar-thumb:hover {
        background-color: #a0a0a5;
    }

    .navList::-webkit-scrollbar-button {
        display: none;
    }
}

ul.navScrollerContent {
    margin: 0;
    padding: 0;
    list-style: none;
}

.navScrollerContent {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    min-height: 32px;
    -webkit-transition: -webkit-transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

:global(.cashbackNotActivated) .navScrollerContent {
    align-items: flex-end;
    margin-bottom: 15px;
}

.navItem {
    padding-right: 40px;
}

.navScrollerItem {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    text-align: center;
    display: flex;
    position: relative;
}

.navLink,
.navLinkActive {
    font-size: 14px;
    font-family: 'Gilroy ExtraBold', sans-serif;
    line-height: 28px;
    text-transform: uppercase;
    color: #13353f;
    transition: color 0.3s;
    white-space: nowrap;
}

.navLinkActive {
    color: #ff6a13;
}

.navLink:hover {
    color: #0097b3;
}
