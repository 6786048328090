.title,
.subtitle {
    font-size: 22px;
    color: #13353f;
    margin: 0;
}

.description {
    margin-bottom: 20px;
}

.subtitle {
    margin-bottom: 20px;
}

.popup {
    margin-top: 0;
    margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
    .selectField {
        width: 100%;
        margin: 30px 0;
    }
}
