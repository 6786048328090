.pageContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.outerContainer {
    box-sizing: border-box;
    width: 100%;
    max-width: 1086px;
    display: flex;
    flex-direction: column;
    align-self: center;

    padding: 45px 45px 70px 45px;
    font-size: 20px;
}

.outerContainer p,
.outerContainer li {
    margin-bottom: 5px;
}

.outerContainer a {
    color: blue;
}

.outerContainer a:hover {
    opacity: 0.8;
}

.outerContainer a:active {
    opacity: 0.5;
}

.stepList {
    padding-left: 25px;
    margin: 10px 0;
    list-style: decimal;
}