.orderListBlock {
    order: -1;
}

.orderListTitle {
    margin-bottom: 20px;
    font-size: 22px;
    color: #13353f;
}

.orderListTotal {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.orderListTotalTitle {
    font-size: 14px;
    font-weight: bold;
}

.orderListTotalPrice {
    font-family: 'Gilroy ExtraBold', sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 33px;
    color: #13353f;
    white-space: nowrap;
}

.orderListTotalPrice div + div {
    font-size: 20px;
    opacity: 0.3;
    text-decoration: line-through;
    text-align: right;
    padding-right: 5px;
}

.checkupInformation {
    margin-top: 25px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #6c8591;
}

.promotions {
    display: flex;
    flex-direction: column;
}

.promotions > div:first-child {
    margin-top: 25px;
}
