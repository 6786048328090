.container {
    background: #e5e5e5;
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

@media only screen and (max-width: 879px) {
    .container {
        min-height: calc(100vh - 138px);
        padding: 15px;
    }
}

@media only screen and (max-width: 600px) {
    .container {
        min-height: calc(100vh - 183px);
    }
}

:global(.navigationBarInner) {
    display: flex;
    margin: 0 auto;
    width: 880px;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width: 879px) {
    :global(.navigationBarInner) {
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    :global(.navigationBarInner) {
        flex-direction: column;
        width: 100%;
    }
}

.searchString {
    display: flex;
    align-items: center;
    width: 560px;
    margin-left: 70px;
    position: relative;
    margin-bottom: 20px;
    margin-top: 20px;
}

@media only screen and (max-width: 600px) {
    .searchString {
        width: 100%;
        margin-left: unset;
        background-color: white;
        margin-bottom: 20px;
        margin-top: unset;
    }
}

.searchString input {
    font-family: Open Sans, sans-serif;
    background: #ffffff;
    border: 2px solid #0097b3 !important;
    border-radius: 85px;
    height: 50px !important;
    padding: 10px 70px 10px 25px !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #13353f;
}

@media only screen and (max-width: 600px) {
    .searchString input,
    .searchString input::placeholder {
        font-size: 13px !important;
        font-weight: 400;
        margin-left: 20px !important;
        border: none !important;
        width: 100% !important;
        padding: 0 40px 0 0 !important;
    }
}

.searchString button {
    position: relative;
    right: 60px;
}

@media only screen and (max-width: 600px) {
    .searchString button {
        right: 40px;
        position: static;
        width: auto !important;
        padding: 3px 0 0 0;
        margin-right: 15px;
    }
}

.searchString svg {
    transform: rotate(90deg);
    stroke: #0097b3 !important;
    width: 20px !important;
    height: 20px !important;
}

@media only screen and (max-width: 600px) {
    .searchString svg {
        transform: none;
    }
}

.clearSearchIcon {
    display: inline-block;
    position: absolute;
    right: 120px;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .clearSearchIcon {
        right: 50px;
    }
}

.cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 880px;
    margin: 0 0 150px 0;
    gap: 40px;
    align-self: center;
}

@media only screen and (min-width: 611px) and (max-width: 879px) {
    .cards {
        width: 580px;
    }
}

@media only screen and (max-width: 610px) {
    .cards {
        grid-template-columns: repeat(1, 1fr);
        flex-direction: column;
        width: auto;
    }
}

.card {
    width: 100%;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px;
}

@media only screen and (max-width: 879px) {
    .card {
        padding: 15px;
    }
}

@media only screen and (max-width: 610px) {
    .card {
        width: 100%;
        margin-top: 15px;
    }

    .card:first-child {
        margin-top: 0;
    }
}

@media only screen and (min-width: 611px) and (max-width: 879px) {
    .card {
        width: 280px;
        padding: 12px 16px;
    }
}

@media only screen and (max-width: 610px) {
    .card:nth-of-type(odd) {
        margin-right: 0;
    }
    .card:nth-of-type(even) {
        margin-left: 0;
    }
}

.infoBlock {
    display: flex;
    justify-content: space-between;
}

@media only screen and (min-width: 611px) and (max-width: 879px) {
    .infoBlock {
        flex-direction: column;
    }
}

.info {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    width: 100%;
}

.topBlock {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
}

.title {
    font-size: 22px;
    font-weight: 800;
    line-height: 28px;
    max-width: 270px;
    flex-grow: 1;
}

.discountSwitchBlock {
    display: flex;
    align-items: center;
}

.selectedSolution .title {
    color: #ff6a13;
}

@media only screen and (max-width: 879px) {
    .title {
        font-size: 18px;
        line-height: 20px;
    }
}

.description {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    text-align: left;
    color: #0097b3;
    font-family: Open Sans, sans-serif;
}

.description:hover {
    cursor: pointer;
    color: #01bde0;
}

@media only screen and (max-width: 879px) {
    .description {
        font-size: 12px;
        line-height: 18px;
    }
}

.discount {
    font-family: 'Gilroy ExtraBold', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 21px;
    text-align: left;
    color: #13353f;
    opacity: 0.3;
    margin-right: 15px;
}

.selectedService {
    background: #0097b3;
}

.disabledService {
    opacity: 0.2;
}

.selectedService .discount {
    opacity: 1;
}

.selectedService .description,
.selectedService .discount,
.selectedService .title {
    color: #ffffff;
}

.selectedService .serviceSwitch div {
    background: #ffffff;
    border-color: #ffffff;
    border-radius: 15px;
}

.selectedService .serviceSwitch div > div::after {
    background-color: #ff6a13;
}

.servicesPanelContainer {
    margin-top: 100px;
    background: #ffffff;
    height: 100px;
    position: fixed;
    bottom: 0;
    padding: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 768px) {
    .servicesPanelContainer {
        margin-top: 64px;
    }
}

@media only screen and (max-width: 600px) {
    .servicesPanelContainer {
        margin-top: 120px;
    }
}

.selectedServicesPanel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 880px;
    gap: 10px;
}

@media only screen and (max-width: 880px) {
    .selectedServicesPanel {
        height: 60px;
        min-height: auto;
    }
}

@media only screen and (max-width: 600px) {
    .selectedServicesPanel {
        height: auto;
        min-height: auto;
        flex-direction: column;
        align-items: stretch;
    }
}

.serviceBoxPanel {
    font-family: Open Sans, sans-serif;
    min-width: 150px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
}

@media only screen and (max-width: 880px) {
    .serviceBoxPanel {
        min-width: 100px;
        height: 35px;
        font-size: 12px;
        line-height: 18px;
    }
}

.emptyServiceBoxPanel {
    background: #e9e9e9;
}

.selectedServicesBoxPanel {
    background: #0097b3;
    color: #ffffff;
}

.selectedServicesBox {
    max-width: 500px;
    display: flex;
    flex-wrap: nowrap;
    max-height: 90px;
    overflow: auto;
    justify-content: center;
    gap: 10px;
}

@media only screen and (max-width: 600px) {
    .selectedServicesBox {
        max-width: none;
        justify-content: space-around;
    }
}

.servicesPanelButtons {
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .servicesPanelButtons {
        width: 100%;
        order: -1;
        align-self: flex-end;
    }
}

.removeServiceIcon,
.clearAllServiceButton img {
    width: 23px;
    height: 23px;
    cursor: pointer;
    margin-left: 10px;
}

@media only screen and (max-width: 880px) {
    .removeServiceIcon,
    .clearAllServiceButton img {
        width: 13px;
        height: 13px;
    }
}

.clearAllServiceButton,
.continueButton {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
}

.clearAllServiceButton:disabled {
    background-color: transparent;
}

.clearAllServiceButton:disabled > img {
    cursor: default;
}

@media only screen and (max-width: 880px) {
    .clearAllServiceButton,
    .continueButton {
        height: 35px;
    }
}

.clearAllServiceButton,
.clearAllServiceButton:hover {
    background: transparent;
    border: none;
    color: #13353f;
    opacity: 0.4;
}

.continueButton {
    background: #ff6a13;
    border: #ff6a13;
}

@media only screen and (max-width: 600px) {
    .continueButton {
        padding: 0 15px;
        flex-grow: 1;
        justify-content: center;
    }
}

@media only screen and (max-width: 400px) {
    .continueButton {
        padding: 0 10px;
        font-size: 14px;
    }
}

.continueButton:hover {
    background: #e75c0f;
}

.continueButton:hover[disabled='disabled'],
.continueButton:hover[disabled] {
    background-color: #afafaf;
}

.clearAllServiceButton img {
    margin-left: 10px;
}

.maxCountSelectedServices {
    color: #ffffff;
    margin-left: 5px;
}

@media only screen and (max-width: 880px) {
    .maxCountSelectedServices {
        margin-left: 15px;
    }
}

.countSelectedServices {
    color: #13353f;
    margin-left: 5px;
}

.noResults {
    text-align: center;
    color: #13353f;
    padding-bottom: 180px;
}

@media only screen and (max-width: 879px) {
    .noResults {
        padding-bottom: 138px;
    }
}

.noResultsIcon {
    width: 416px;
    height: 168px;
}

@media only screen and (max-width: 600px) {
    .noResultsIcon {
        width: 332px;
        height: 134px;
    }
}

.noResultsTitle {
    font-family: 'Gilroy ExtraBold', sans-serif;
    font-weight: 800;
    font-size: 30px;
    line-height: 34px;
    margin-top: 22px;
    margin-bottom: 5px;
}

@media only screen and (max-width: 768px) {
    .noResultsTitle {
        font-size: 18px;
        line-height: 21px;
    }
}

.noResultsInfo {
    font-family: Open Sans, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
}

@media only screen and (max-width: 768px) {
    .noResultsInfo {
        font-size: 12px;
        line-height: 18px;
    }
}

@media only screen and (max-width: 879px) {
    .noResults {
        padding-bottom: 138px;
    }
}

.loaderContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 1000;
    overflow-y: auto;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.75);
}
