.descriptionBlock {
    font-size: 14px;
    font-weight: 400;
    color: #13353f;
    margin-top: 30px;
    width: 50%;
    padding-right: 25px;
}

@media only screen and (max-width: 768px) {
    .descriptionBlock {
        width: 100%;
    }
}

.descriptionTitle {
    margin-bottom: 22px;
    font-weight: 700;
    font-size: 18px;
}

.descriptionBlockList {
    font-size: 14px;
    font-weight: 400;
    color: #13353f;
    margin-bottom: 20px;
    margin-left: 15px;
    list-style-type: decimal;
}
