@media only screen and (max-width: 480px) {
    .modal {
        padding-bottom: 100px;
    }
}

.formGroup {
    display: flex;
    flex-wrap: wrap;
}

.buttonsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

@media only screen and (max-width: 480px) {
    .buttonsContainer {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
    }

    .buttonsContainer button {
        height: 40px;
        font-size: 14px;
        line-height: 38px;
        margin: 10px 5px 0 5px;
        font-weight: normal;
    }
}

.formGroup > *,
.modal label:first-child {
    margin-right: 10px;
    margin-left: 10px;
}

.formGroup.stretch > * {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 200px;
}

.formGroup > button {
    font-family: 'PT Sans', sans-serif;
    border-width: 1px;
}

.checkbox {
    align-items: flex-start;
    margin-bottom: 30px;
}
