.orderListItems {
    margin-bottom: 20px;
}

.orderListItem,
.discount {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
}

.orderListItemTitle,
.discountTitle {
    font-weight: normal;
}

.orderListItemPrice,
.discountValue {
    margin-left: 5px;
    font-weight: bold;
}
