.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    z-index: 100;
    overflow-y: auto;
}

.container {
    position: relative;
    display: block;
    padding: 40px;
    background-color: white;
    margin: auto;
}
@media only screen and (max-width: 768px) {
    .container {
        padding: 20px;
    }
}

@media only screen and (max-width: 360px) {
    .container {
        padding: 15px;
    }
}


.closeBtn {
    display: block;
    position: absolute;
    right: 30px;
    top: 6px;
    font-size: 36px;
    cursor: pointer;
}
