.approvalData > label {
    margin-bottom: 30px;
}

.approvalData div:last-child {
    margin-bottom: 0;
}

.email > label,
.approvalData > label {
    color: rgb(21, 53, 62);
    font-family: 'Gilroy ExtraBold', sans-serif;
    font-size: 16px;
}

.email.error > label {
    color: var(--color-red-1);
}

.email input:focus {
    border-color: #e8eced;
}
.email.error input:focus {
    border-color: var(--color-red-1);
}

.inputEmail.error {
    border: 1px solid var(--color-red-1);
    color: var(--color-black-1);
}

.textError {
    color: var(--color-red-1);
}
