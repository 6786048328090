.title {
    margin-bottom: 6px;
}

.content {
    display: flex;
    gap: 10px;
    padding: 15px;
    border: 1px solid var(--color-gray-1);
    align-items: center;
}

.input {
    width: 100%;
    border: unset;
}
