.reorderModal {
    max-width: 640px;
}

@media only screen and (max-width: 950px) {
    .reorderModal {
        max-width: 100vw;
    }
}

.choose {
    margin-right: 30px;
}

@media only screen and (max-width: 950px) {
    .choose {
        margin-right: 0;
    }
}

.buttons {
    margin-top: 10px;
}

@media only screen and (max-width: 950px) {
    .buttons {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

.countProducts {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #15353e;
}