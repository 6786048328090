.container {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #5c7d88;
}

.link {
    font-family: 'Gilroy ExtraBold', sans-serif;
    display: inline-block;
    margin-right: 32px;
    font-size: 18px;
    line-height: 28px;
    color: #5c7d88;
}

.image {
    height: 24px;
    vertical-align: text-top;
    padding-right: 5px;
}
