.nav {
    margin-bottom: 0;
    padding-left: 0;
    white-space: nowrap;
}

@media only screen and (max-width: 430px) {
    .nav {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .nav li:nth-of-type(5) {
        display: none;
    }
}

@media only screen and (max-width: 660px) {
    .nav li:nth-of-type(4) {
        display: none;
    }
}

@media only screen and (max-width: 580px) {
    .nav li:nth-of-type(3) {
        display: none;
    }
    .nav li:nth-of-type(4) {
        display: none;
    }
}

@media only screen and (max-width: 480px) {
    .nav li:nth-of-type(2) {
        display: none;
    }
}

.navItem {
    display: inline-block;
}

.navLink {
    height: 60px;
    display: flex;
    flex-wrap: wrap;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 14px;
    line-height: 60px;
    color: #13353f;
    -webkit-transition: color 0.3s, -webkit-box-shadow 0.3s;
    -o-transition: color 0.3s, box-shadow 0.3s;
    transition: color 0.3s, box-shadow 0.3s, -webkit-box-shadow 0.3s;
    cursor: pointer;
    -webkit-box-shadow: inset 0 -5px 0 0 transparent;
    box-shadow: inset 0 -5px 0 0 transparent;
}

.navLink:hover {
    color: #1797b1;
    -webkit-box-shadow: inset 0 -5px 0 0 #dcdfe0;
    box-shadow: inset 0 -5px 0 0 #dcdfe0;
}

@media only screen and (max-width: 1280px) {
    .navLink {
        padding-left: 8px;
        padding-right: 8px;
        font-size: 12px;
    }
}

@media only screen and (max-width: 768px) {
    .navLink {
        margin-bottom: 8px;
        line-height: 68px;
    }
}
