.container {
    width: 100%;
    text-align: center;
}

.container .alert {
    margin: 0;
}

.container .button {
    margin-top: 75px;
    margin-bottom: 40px;
}
