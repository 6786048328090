.emailModal {
    min-width: 400px;
}

@media only screen and (max-width: 380px) {
    .emailModal {
        min-width: auto;
    }
}

.emailModal .sendBtn {
    width: 100%;
}

.emailLangSelector {
    display: block;
}

.text {
    width: 90px;
}
