@import '~reset-css';

@font-face {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('PT Sans'), local('PTSans-Regular'), url(./fonts/ptsans/ptsans-regular-cyrillic-ext.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('PT Sans'), local('PTSans-Regular'), url(./fonts/ptsans/ptsans-regular-cyrillic.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('PT Sans'), local('PTSans-Regular'), url(./fonts/ptsans/ptsans-regular-latin-ext.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('PT Sans'), local('PTSans-Regular'), url(./fonts/ptsans/ptsans-regular-latin.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
        U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(./fonts/opensans/opensans-regular-cyrillic-ext.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(./fonts/opensans/opensans-regular-cyrillic.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(./fonts/opensans/opensans-regular-latin-ext.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(./fonts/opensans/opensans-regular-latin.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
        U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(./fonts/opensans/opensans-semibold-cyrillic-ext.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(./fonts/opensans/opensans-semibold-cyrillic.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(./fonts/opensans/opensans-semibold-latin-ext.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(./fonts/opensans/opensans-semibold-latin.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
        U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(./fonts/opensans/opensans-bold-cyrillic-ext.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(./fonts/opensans/opensans-bold-cyrillic.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(./fonts/opensans/opensans-bold-latin-ext.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(./fonts/opensans/opensans-bold-latin.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
        U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'), url(./fonts/opensans/opensans-extrabold-cyrillic-ext.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'), url(./fonts/opensans/opensans-extrabold-cyrillic.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'), url(./fonts/opensans/opensans-extrabold-latin-ext.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'), url(./fonts/opensans/opensans-extrabold-latin.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
        U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'ALS Hauss';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('ALS Hauss Regular'), url(./fonts/alshauss/als_hauss_regular.woff2) format('woff2');
}

@font-face {
    font-family: 'ALS Hauss';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('ALS Hauss Medium'), url(./fonts/alshauss/als_hauss_medium.woff2) format('woff2');
}

@font-face {
    font-family: 'ALS Hauss';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('ALS Hauss Bold'), url(./fonts/alshauss/als_hauss_bold.woff2) format('woff2');
}

@font-face {
    font-family: 'Gilroy ExtraBold';
    src: url('./fonts/gilroyextrabold/gilroy-extrabold.eot'); /* IE9*/
    src: url('./fonts/gilroyextrabold/gilroy-extrabold.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('./fonts/gilroyextrabold/gilroy-extrabold.woff2') format('woff2'),
        /* chrome firefox */ url('./fonts/gilroyextrabold/gilroy-extrabold.woff') format('woff'),
        /* chrome firefox */ url('./fonts/gilroyextrabold/gilroy-extrabold.ttf') format('truetype'),
        /* chrome firefox opera Safari, Android, iOS 4.2+*/ url('./fonts/gilroyextrabold/gilroy-extrabold.svg#Gilroy ExtraBold') format('svg'); /* iOS 4.1- */
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    font-family: 'PT Sans', sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    color: #15353e;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Gilroy ExtraBold', sans-serif;
}

h1 {
    margin-bottom: 10px;
    font-size: 48px;
    line-height: 1;
}

h2 {
    margin-bottom: 28px;
    color: #13353f;
    font-size: 26px;
}

b {
    font-weight: bold;
}
