.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 1000;
    overflow-y: auto;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.75);
}
