.container {
    display: table;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    table-layout: fixed;
}

.container main {
    height: 100%;
    width: 100%;
    color: #13353f;
    font-family: 'PT Sans', sans-serif;
    line-height: 1.5;
}

.backLink {
    width: 30px;
    height: 20px;
    left: 30px;
    top: 30px;
    cursor: pointer;
    position: relative;
}

@media only screen and (max-width: 768px) {
    .backLink {
        width: 20px;
        height: 13px;
        left: 20px;
        top: 20px;
    }
}
