.orderAcceptedContainer {
    max-width: 415px;
    padding: 50px 26px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
}

@media only screen and (max-width: 480px) {
    .orderAcceptedContainer {
        padding: 30px 15px;
    }
}

.firstSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    text-align: center;
    padding-bottom: 24px;
}

.orderAcceptedIcon {
    width: 52px;
    height: 52px;
}

.orderNumber {
    margin-top: 20px;
    font-size: 17px;
}

.orderNumberLink {
    color: #000;
}

.orderNumberLink:hover {
    color: #0097b3;
}

.orderAcceptedTitle {
    font-family: 'Gilroy ExtraBold', sans-serif;
    font-weight: 800;
    font-size: 30px;
    color: #13353f;
    line-height: 112%;
}

.dateTimeNotice {
    font-size: 16px;
    padding: 24px 0;
    border-top: 1px solid #cfd4d5;
}

.surveyBlock {
    margin-top: 20px;
    border-top: 3px dotted #000;
    padding: 20px 0;
    text-align: center;
}

.surveyInner {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
}

.surveyText {
    font-weight: 800;
    font-size: 20px;
    text-align: left;
    padding-right: 5px;
}

.surveyText a {
    color: #0097b3;
}

@media only screen and (max-width: 480px) {
    .surveyText {
        font-size: 13px;
    }
}

.surveyButton {
    width: 100%;
}

.documentNotice {
    font-size: 16px;
    font-weight: 600;
    color: #13353f;
    display: flex;
    align-items: center;
    padding: 24px 0;
    background: #ffffff;
    border-top: 1px solid #cfd4d5;
}

.overdueNotice {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    border-top: 1px solid #cfd4d5;
    padding: 24px 0;
    font-size: 16px;
    line-height: 26px;
    color: #13353f;
}

@media only screen and (max-width: 480px) {
    .documentNotice {
        font-size: 12px;
    }
}

.onlineRegistrationBlock {
    border-top: 1px solid #cfd4d5;
    padding: 24px 0;
}

.onlineRegistrationBlock h2 {
    font-family: 'Gilroy ExtraBold', sans-serif;
    text-align: center;
    font-weight: 800;
    font-size: 30px;
    line-height: 112%;
}

.step {
    display: flex;
    align-items: center;
    margin-top: 24px;
}

.step > .number {
    font-family: 'Gilroy ExtraBold', sans-serif;
    border: 2px solid #0097b3;
    border-radius: 50%;
    text-align: center;
    width: 32px;
    height: 32px;
    font-size: 16px;
    font-weight: 800;
    line-height: 30px;
    margin-right: 25px;
    flex: 0 0 32px;
    color: #0097b3;
}

.onlineRegistrationWarning {
    padding: 24px 0;
    font-size: 12px;
    line-height: 160%;
}

.office {
    border-top: 1px solid #cfd4d5;
    padding-top: 24px;
    display: flex;
    align-items: center;
}

.office img {
    padding-right: 15px;
}

.office h3,
.office p {
    font-size: 12px;
    line-height: 150%;
}

.office h3 {
    font-weight: 700;
}

.office p {
    color: #0097b3;
}

.documentNotice span {
    color: #ff6a13;
}

.prepareLink,
.printLink {
    width: 50%;
    background: #0097b3;
    padding: 30px 15px 15px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.prepareText,
.printText {
    font-family: 'Open Sans', sans-serif;
    color: #ffffff;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
}

@media only screen and (max-width: 480px) {
    .prepareLink,
    .printLink {
        font-size: 12px;
    }
}

.prepareLink:hover,
.printLink:hover {
    background: #21b4d0;
}

.prepareIcon,
.printIcon {
}

.documentNote {
    font-weight: 600;
    font-size: 9px;
    color: #13353f;
    margin-bottom: 24px;
    line-height: 150%;
}

.secondSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    background: #ffffff;
    padding-top: 24px;
}

.linkBlock {
    display: flex;
}

.phonesBlock {
    border-top: 1px solid #cfd4d5;
    padding-top: 24px;
}

.phonesText {
    font-weight: 600;
    font-size: 14px;
    color: #13353f;
}

.phones {
}

.phones a:hover {
    color: #ff6a13;
}

.phone {
    margin-top: 14px;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    color: #13353f;
}

@media only screen and (max-width: 480px) {
    .phone {
        font-size: 16px;
    }
}

.phoneFree {
    font-size: 9px;
    font-weight: 600;
    line-height: 150%;
}

@media print {
    header,
    footer,
    :global(.up-button) {
        visibility: hidden;
    }
}
