.container {
    font-family: PT Sans, sans-serif;
    font-size: 14px;
    line-height: 21px;
    margin: 20px 0 0 0;
}

.container h3 {
    font-size: 14px;
    font-family: PT Sans, sans-serif;
    font-weight: bold;
    color: #15353e;
}

.discount {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: baseline;
    flex-wrap: nowrap;
}

.name div {
    margin-left: 10px;
}

.value {
    font-weight: bold;
    white-space: nowrap;
}
