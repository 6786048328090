.authBlockContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.authBlockButton {
    width: 165px;
}

.authBlockLink {
    color: #0097b3;
    transition: all 0.3s;
    border-bottom: 1px dashed #0097b3;
    font-size: 14px;
    margin-top: 30px;
}

.authBlockLink:hover {
    color: #06859d;
    border-bottom: 1px dashed #06859d;
    transition: all 0.3s;
}

.vndTitle {
    font-size: 18px;
    text-align: center;
}
