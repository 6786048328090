.overlayClassName {
    background: #f6f6f6;
}

.overlayClassName > div {
    padding: 0;
}

.overlayClassName > div > button {
    position: fixed;
    right: 74px;
    top: 52px;
}

@media only screen and (max-width: 600px) {
    .overlayClassName > div > button {
        right: 38px;
        top: 10px;
    }
}

.container {
    padding: 47px 68px;
    display: flex;
    width: 648px;
    flex-direction: column;
}

@media only screen and (max-width: 600px) {
    .container {
        padding: 32px 30px;
        width: 100%;
    }
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.title {
    font-family: 'Gilroy ExtraBold', sans-serif;
    font-weight: 800;
    font-size: 24px;
    line-height: 28px;
    color: #13353f;
    max-width: 370px;
}

@media only screen and (max-width: 600px) {
    .title {
        width: 241px;
        font-size: 16px;
        line-height: 19px;
    }
}

@media only screen and (max-width: 600px) {
    .cartIcon,
    .cartErrorIcon {
        height: 30px;
        width: 30px;
        align-self: flex-start;
    }
}

.buttons {
    display: flex;
    margin-top: 48px;
    justify-content: flex-end;
    padding-left: 40px;
}

@media only screen and (max-width: 600px) {
    .buttons {
        margin-top: 12px;
        padding-left: 0;
    }
}

.buttons button {
    font-family: 'Gilroy ExtraBold', sans-serif;
    font-weight: 800;
    font-size: 16px;
    line-height: 18px;
    color: #0097b3;
    margin-right: 20px;
    padding: 0 30px;
    height: 48px;
}

@media only screen and (max-width: 600px) {
    .buttons button {
        height: 36px;
        font-size: 13px;
        line-height: 14px;
        padding: 11px 0;
        margin-right: 20px;
    }
}

.buttons button:hover {
    border-radius: 100px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    outline: none;
}

.buttons button:last-child {
    margin-right: 0;
    color: #ffffff;
}

@media only screen and (max-width: 600px) {
    .buttons button:last-child {
        padding: 11px 23px;
    }
}
