.wrapper {
    background: #e5e5e5;
    display: flex;
    justify-content: center;
    min-height: 100vh;
}

@media only screen and (max-width: 950px) {
    .wrapper {
        padding: 15px;
        /* 100 минус высота навбара */
        min-height: 94.5vh;
    }
}

.content {
    background: #fff;
    max-width: 1071px;
    width: 100%;
    margin: 50px auto;
    padding: 25px;
    height: fit-content;
}

@media only screen and (max-width: 950px) {
    .content {
        width: 100%;
        padding: 30px 0 25px;
        margin: 0;
        height: auto;
    }
}

.toolBarWrapper {
    padding: 0 25px;
}

.toolBar {
    display: flex;
    border-bottom: 1px solid #e8eced;
}

@media only screen and (max-width: 950px) {
    .toolBar {
        flex-direction: column;
    }
}

.header {
    display: flex;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #15353e;
    border-bottom: 1px solid #e8eced;
    padding: 43px 25px 18px 50px;
}

.header div {
    margin-right: 44px;
}

.header div:last-of-type {
    margin-right: 0;
}

@media only screen and (max-width: 950px) {
    .header {
        display: none;
    }
}

.date {
    max-width: 128px;
    width: 100%;
}

.event {
    width: 334px;
}

.accured {
    max-width: 76px;
    width: 100%;
    text-align: right;
}

.writeOff {
    max-width: 76px;
    width: 100%;
    text-align: right;
}

.paid {
    max-width: 108px;
    width: 100%;
    text-align: right;
}

.dateRangeContainer {
    display: flex;
}

@media only screen and (max-width: 950px) {
    .dateRangeContainer {
        flex-direction: column;
    }
}

.container {
    margin-right: 52px;
}

@media only screen and (max-width: 950px) {
    .container {
        margin-right: 0;
    }
}

.datesSeparator {
    width: 10px;
    height: 1px;
    background-color: #0097b3;
    margin-left: 10px;
    margin-right: 10px;
    flex-shrink: 0;
    margin-top: 25px;
}

@media only screen and (max-width: 950px) {
    .datesSeparator {
        width: 0px;
        height: 0px;
    }
}

.body {
    margin: 0 25px;
}

@media only screen and (max-width: 950px) {
    .body {
        margin: 0;
    }
}

.selectPicker {
    margin-right: 50px;
}

@media only screen and (max-width: 950px) {
    .selectPicker {
        margin-right: 0;
    }
}

.loaderContainer {
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.errorContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.errorContainer > div {
    width: 100%;
    margin-top: 0;
}

.retryBtn {
    margin: 30px 0 !important;
}
