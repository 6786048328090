.container {
    width: 100%;
    position: relative;
}

.container input.valid {
    padding-right: 45px;
}

.validIcon {
    display: block;
    background: url('./valid.svg') center no-repeat;
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
    transform: translate(-15px, -50%);
    position: absolute;
    top: 50%;
    right: 0;
}
