.container {
    position: relative;
    min-width: 150px;
}

.input {
    padding-right: 45px;
}

.icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-15px, -50%);
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('./icon-date.svg') center no-repeat;
}

.icon:hover {
    cursor: pointer;
}

.calendar {
}

.calendar .react-datepicker__week:nth-child(3n + 2) {
    background-color: #eea429;
}

:global(.react-datepicker__aria-live) {
    display: none;
}
