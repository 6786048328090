.card {
    display: flex;
    flex-direction: column;
    background: #f04e5c;
    padding: 25px;
    color: #ffffff;
    justify-content: space-between;
    width: 415px;
    margin: 0 auto;
    align-self: center;
}

@media only screen and (max-width: 600px) {
    .card {
        margin-top: 15px;
    }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
    .card {
        width: 225px;
    }
}

@media only screen and (max-width: 600px) {
    .card {
        width: 100%;
        align-self: flex-start;
    }
}

.infoBlock {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
    .infoBlock {
        flex-direction: column;
        font-size: 12px;
    }
}

.info {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
    .icon {
        order: -1;
        align-self: flex-start;
    }
}

.title {
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    text-align: left;
    margin-bottom: 15px;
}

@media only screen and (max-width: 768px) {
    .title {
        font-size: 16px;
        line-height: 18px;
    }
}

.card button {
    background: #ffffff;
    color: #f04e5c;
    border: none;
    margin-top: 20px;
    align-self: flex-end;
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
    .card button {
        align-self: flex-start;
    }
}

.card button:hover {
    color: #f04e5c;
}

@media only screen and (max-width: 768px) {
    .card button {
        font-size: 13px;
    }
}
