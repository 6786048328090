.table div[role='cell'],
.table div[role='columnheader'] {
    min-width: 120px;
}

.footnote {
    font-size: 14px;
    color: #ff6a13;
    padding: 10px 0 10px;
}
