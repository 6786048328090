@keyframes slideLeftToCenter {
    100% {
        left: 0;
    }
}

@keyframes slideRightToCenter {
    100% {
        right: 0;
    }
}

.overlay {
    padding: 72px 0;
    scroll-behavior: smooth;
}

@media only screen and (max-width: 768px) {
    .overlay {
        padding: 48px 0;
    }
}

@media only screen and (max-width: 600px) {
    .overlay {
        padding: 0;
    }
}

.modal {
    max-width: 1112px;
    padding: 48px 42px 0 42px !important;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 100%;
}

@media only screen and (max-width: 768px) {
    .modal {
        right: -100vw;
        animation: slideRightToCenter 0.4s forwards;
        max-width: 582px;
        padding: 0 !important;
    }
}

@media only screen and (max-width: 600px) {
    .modal {
        max-width: 100%;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.modal > button {
    background-color: #f6f6f6;
    width: 48px !important;
    height: 48px;
    border-radius: 100px;
    line-height: 28px;
    z-index: 1;
}

@media only screen and (min-width: 769px) {
    .modal > button {
        background-color: #f6f6f6;
        width: 48px !important;
        height: 48px;
        border-radius: 100px;
        line-height: 28px;
        top: 40px;
        z-index: 1;
        position: absolute !important;
    }
}

@media only screen and (max-width: 768px) {
    .modal > button {
        width: 32px !important;
        height: 32px;
        top: 24px;
        right: 26px;
        transition: none !important;
    }
}

@media only screen and (max-width: 600px) {
    .modal > button {
        top: 12px !important;
        right: 14px !important;
    }
}

.modal > button:hover {
    background-color: #e7e7e7;
}

.title {
    font-family: Gilroy, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 34px;
    color: #000000;
    margin-right: 80px;
}

.description {
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #13353f;
    margin-top: 30px;
}

@media only screen and (max-width: 768px) {
    .title {
        font-size: 18px;
        line-height: 21px;
        padding: 24px 26px 0 24px;
    }
}

@media only screen and (max-width: 600px) {
    .title {
        padding: 12px 14px 0 14px;
    }
}

@media only screen and (max-width: 768px) {
    .description {
        padding: 24px 26px 0 24px;
        margin-top: 10px;
    }
}

@media only screen and (max-width: 600px) {
    .description {
        padding: 12px 14px 0 14px;
    }
}

.modal .description a {
    color: #0097b3;
}

.modal .description a:hover {
    color: #ff6a13;
}

.root {
    box-sizing: border-box;
    padding-bottom: 100px;
    font-family: 'PT Sans', sans-serif;
    -webkit-tap-highlight-color: transparent;
}

@media only screen and (max-width: 600px) {
    .root {
        padding-bottom: 54px;
    }
}

@media only screen and (max-width: 768px) {
    .modal h1:first-child {
        padding: 0;
        font-size: 26px;
        line-height: 32px;
    }
}
