.section {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    border-bottom: 1px solid #e8eced;
}

.section:hover {
    background-color: #f3fafb;
    -webkit-box-shadow: 0 5px 7px 0 rgba(92, 125, 136, 0.2);
    box-shadow: 0 5px 7px 0 rgba(92, 125, 136, 0.2);
}

.arrow {
    margin: 0px;
    transition: transform 0.6s ease;
}

.rotate {
    transform: rotate(180deg);
}

.record {
    display: flex;
}

.container {
    padding: 20px 25px 20px 50px;
    cursor: pointer;
    display: flex;
    outline: none;
    position: relative;
}

.container div {
    margin-right: 44px;
}

@media only screen and (max-width: 950px) {
    .container div {
        margin-right: 0;
    }
}

.container div:last-of-type {
    margin-right: 0;
}

.container .discountEvent {
    margin-right: 0;
}

@media only screen and (max-width: 950px) {
    .container {
        flex-direction: column;
        padding-left: 44px;
    }
}

.date {
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #15353e;
    max-width: 128px;
    width: 100%;
}

@media only screen and (max-width: 950px) {
    .date {
        margin-bottom: 8px;
    }
}

.event {
    font-size: 16px;
    line-height: 150%;
    max-width: 334px;
    width: 100%;
}

.discountEvent {
    max-width: 100%;
    width: calc(100% - 172px);
    margin-right: 0;
}

@media only screen and (max-width: 950px) {
    .event {
        width: fit-content;
        margin-bottom: 8px;
    }
}

.accured {
    font-size: 16px;
    line-height: 150%;
    color: #1797b1;
    max-width: 76px;
    width: 100%;
    text-align: right;
}

.writeOff {
    font-size: 16px;
    line-height: 150%;
    color: #15353e;
    max-width: 76px;
    width: 100%;
    text-align: right;
}

.paid {
    width: 108px;
    text-align: right;
}

@media only screen and (max-width: 950px) {
    .paid {
        text-align: left;
    }
}

.amount {
    margin-right: 5px;
    color: #aeb1b3;
    font-size: 12px;
    text-decoration: line-through;
}

.amountWithDiscount {
    font-size: 16px;
    font-weight: 700;
    color: #15353e;
    max-width: 108px;
    width: 100%;
    text-align: right;
}

.count {
    font-size: 16px;
    line-height: 150%;
    color: #1797b1;
    max-width: 76px;
    width: 100%;
    text-align: right;
    margin-right: 44px;
}

@media only screen and (max-width: 950px) {
    .mb {
        text-align: left;
        max-width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 950px) {
    .mb:last-child {
        margin-bottom: 0;
    }
}

.item {
    display: flex;
    border-top: 1px solid #e8eced;
    padding: 18px 0 21px;
}

@media only screen and (max-width: 950px) {
    .item {
        display: block;
    }
}

.article {
    font-size: 12px;
    color: #757575;
}

@media only screen and (max-width: 950px) {
    .article {
        margin-bottom: 10px;
    }
}

.name {
    color: #15353e;
}

.paid {
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #15353e;
}

.contentWrapper {
    background-color: white;
    overflow: hidden;
    transition: max-height 0.6s ease;
    padding-left: 50px;
    padding-right: 25px;
}

.arrowWrapper {
    position: absolute;
    left: 17px;
    top: 25%;
}

@media only screen and (max-width: 950px) {
    .arrowWrapper {
        left: 25px;
        top: 17px;
    }
}

.arrowWrapper img {
    margin: 0px;
}

.product {
    max-width: 547px;
    width: 100%;
}

@media only screen and (max-width: 950px) {
    .product {
        width: 100%;
        margin-bottom: 10px;
    }
}

.paid {
    width: 184px;
}

.opened {
    border: 1px solid #0097B3;
}

.mobile {
    display: none;
}

@media only screen and (max-width: 950px) {
    .none {
        display: none;
    }
}

@media only screen and (max-width: 950px) {
    .mobile {
        display: inline;
        color: #757575;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
    }
}

@media only screen and (max-width: 950px) {
    .show {
        display: inline;
    }
}

@media only screen and (min-width: 951px) {
    .nested {
        max-width: 228px;
        padding-right: 152px;
    }
}