.container {
    display: flex;
}

.container input {
    height: 50px;
    text-align: center;
    font-size: 26px;
    font-weight: 800;
    line-height: 26px;
    padding: 13px 15px 13px 15px;
}

.container input:not(:first-child) {
    margin-left: 10px;
}

.error {
    border: 1px solid #eb3223 !important;
}
