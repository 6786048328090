.table {
    width: 100%;
}

.header,
.body {
    display: inline-flex;
    flex-direction: column;
    flex-grow: 1;
    width: fit-content;
    min-width: 100%;
}

.table .th {
    font-size: 16px;
    font-weight: bold;
}

.table .th,
.table .td {
    flex-grow: 1;
    flex-basis: 0;
}

.table .td {
    font-size: 14px;
}

.table .th,
.table .td {
    text-align: left;
    background-color: white;
    padding: 10px 0;
}

.table .tr {
    flex-grow: 1;
    border-bottom: 1px solid #cbd0d1;
}

.table .body .tr:hover {
    background-color: #f3fafb;
    border-bottom: solid 1px #e8eced;
    transition: 0.5s !important;
}

.table .body .tr:hover .td {
    background-color: #f3fafb;
    transition: 0.5s !important;
}

.table.sticky {
    overflow: auto;
}

.table.sticky .header,
.table.sticky .footer {
    position: sticky;
}

.table.sticky .header {
    top: 0;
}

.table.sticky .footer {
    bottom: 0;
}

.table.sticky .body {
    position: relative;
}

.table.sticky [data-sticky-td] {
    position: sticky;
}
