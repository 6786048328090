.selectPickerContainer :global(.simple-selectpicker__control),
.selectPickerContainer :global(.simple-selectpicker-large__control) {
    height: 50px;
    border-radius: unset;
    border: 1px solid #e8eced;
    box-shadow: none;
}
.selectPickerError :global(.simple-selectpicker__control) {
    border-color: red;
}

.selectPickerContainer :global(.simple-selectpicker__control) {
    min-width: 130px;
}

.selectPickerContainer :global(.simple-selectpicker-large__control) {
    min-width: 250px;
}

.selectPickerContainer :global(.simple-selectpicker__placeholder),
.selectPickerContainer :global(.simple-selectpicker-large__placeholder) {
    white-space: nowrap;
}

.selectPickerContainer :global(.simple-selectpicker__control:hover),
.selectPickerContainer :global(.simple-selectpicker__control--is-focused),
.selectPickerContainer :global(.simple-selectpicker-large__control:hover),
.selectPickerContainer :global(.simple-selectpicker-large__control--is-focused) {
    border-color: #0096a9;
}

.selectPickerContainer :global(.simple-selectpicker__indicator-separator),
.selectPickerContainer :global(.simple-selectpicker-large__indicator-separator) {
    display: none;
}

.selectPickerContainer :global(.simple-selectpicker__menu),
.selectPickerContainer :global(.simple-selectpicker-large__menu) {
    margin: 0 !important;
    width: 100% !important;
    border-radius: unset !important;
    z-index: 5;
}

.selectPickerContainer :global(.simple-selectpicker__menu-list),
.selectPickerContainer :global(.simple-selectpicker-large__menu-list) {
    padding: 0 !important;
}

.selectPickerContainer :global(.simple-selectpicker__option),
.selectPickerContainer :global(.simple-selectpicker-large__option) {
    color: #0097b3 !important;
    background-color: white !important;
    cursor: pointer !important;
}

.selectPickerContainer :global(.simple-selectpicker__option:hover),
.selectPickerContainer :global(.simple-selectpicker-large__option:hover),
.selectPickerContainer :global(.simple-selectpicker__option--is-focused:hover:not(:first-child)),
.selectPickerContainer :global(.simple-selectpicker-large__option--is-focused:hover:not(:first-child)) {
    color: white !important;
    background-color: #0097b3 !important;
}

.selectPickerContainer :global(.simple-selectpicker__option:hover .checkbox div),
.selectPickerContainer :global(.simple-selectpicker-large__option:hover .checkbox div) {
    color: white !important;
}

.phoneRegistrationNotice {
    font-family: 'PT Sans', sans-serif;
    color: #757575;
    width: 100%;
    border: none;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    background: #ffffff;
    padding: 12px 30px 12px 12px;
}