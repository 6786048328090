.container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 500px;
}

.icon {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
}

.title {
    font-family: Gilroy, sans-serif;
    font-size: 26px;
    font-weight: 800;
    line-height: 35px;
    text-align: center;
    margin-bottom: 20px;
}

.description {
    font-family: 'PT Sans', sans-serif;
    color: #15353e;
    text-align: center;
    font-weight: 100;
    margin-bottom: 30px;
}

.buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.buttons button:not(:first-child) {
    margin-top: 30px;
}

.container button {
    width: 100%;
}
