.button {
    position: relative;
}

.button svg {
    position: absolute;
    top: 13px;
    width: 22px;
    height: 22px;
}

.button.iconLeft {
    padding-left: 55px;
}

.button.iconRight {
    padding-right: 55px;
}

.button.iconLeft svg {
    left: 20px;
}

.button.iconRight svg {
    right: 20px;
}
