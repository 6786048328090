.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

:root {
    --color-white-1: #fff;

    --color-black-1: #13353f;

    --color-gray-1: #e8eced;

    --color-green-1: #97da30;
    --color-green-2: #81bc26;
    --color-green-3: #658692;

    --color-red-1: #fc6a77;

    --color-blue-1: #1797b1;

    --color-black-transparment-1: rgba(19, 53, 63, 0.36);

    --font-size-xs: 12px;
    --font-size-sm: 14px;
    --font-size-md: 16px;

    --font-family-titles: 'Gilroy ExtraBold', sans-serif;
}
