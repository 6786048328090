.main {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 500px;
    padding: 100px 20px;
}

@media only screen and (max-width: 768px) {
    .container {
        padding: 50px 20px;
    }
}

.title {
    margin-bottom: 20px;
    font-weight: 100;
    line-height: 26px;
}

.description {
    font-family: 'PT Sans', sans-serif;
    color: #757575;
    font-weight: 100;
    margin-bottom: 50px;
}

.description span {
    color: #15353e;
    font-weight: normal;
}

.link {
    color: #0097b3;
}

.buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.buttons button {
    width: 100%;
}

.buttons button:not(:first-child) {
    margin-top: 30px;
}

.buttonLink {
    margin-top: 30px;
}

.resend {
    display: inline-flex;
    width: auto;
    margin: 20px 0;
}

.passwordRepeat {
    margin-top: 20px;
}

.field {
    font-family: 'PT Sans', sans-serif;
    margin-top: 50px;
}

.description + .field {
    margin-top: 0;
}

.checkbox {
    margin-bottom: 30px;
}

.timer {
    display: inline-block;
}

.alertWarning {
    width: 100%;
    margin: 0 0 40px 0;
}

.smartCaptcha {
    margin-top: 30px;
}
