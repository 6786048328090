.title {
    font-size: 22px;
    color: #13353f;
    margin-bottom: 20px;
}

.popup {
    margin-top: 0;
    margin-bottom: 20px;
}
