.container {
    position: relative;
    flex-direction: column;
    justify-content: center;
    color: #5c7d88;
    display: inline-block;
}

.content {
    position: absolute;
    display: flex;
    padding: 9px 5px 9px 20px;
    z-index: 99;
    background: #ff6a13;
    align-items: center;
    border-radius: 5px;
    justify-content: space-between;
    bottom: 35px;
    right: -10px;
    box-shadow: 0 5px 20px 5px rgb(92 125 136 / 15%);
}

.show {
    opacity: 1;
    transition: opacity 1s;
}

.hide {
    opacity: 0;
    transition: opacity 1s;
}

.content p {
    font-family: 'PT Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    color: #ffffff;
}

.content:after {
    content: '';
    right: 3px;
    top: 19px;
    width: 7px;
    position: relative;
    border-style: solid;
    border-width: 15px 8px 0;
    border-color: #ff6a13 transparent transparent;
}

.icon {
    height: 14px;
    width: 14px;
    position: relative;
    margin-left: 3px;
}

.closeIcon {
    align-self: flex-start;
    bottom: 3px;
    left: 15px;
    position: relative;
}

.closeIcon:hover {
    cursor: pointer;
}
