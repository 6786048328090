.popup {
    background: #ff6a13;
    margin-top: 24px;
    text-align: center;
    width: 100%;
    padding: 15px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 140%;
    border-radius: 10px;
    position: relative;
}

.popup:before {
    content: '';
    background: #ff6a13;
    height: 12px;
    width: 12px;
    position: absolute;
    display: block;
}

.popup.bottom:before {
    top: -6px;
    left: 50%;
    margin-left: -6px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.popup.top:before {
    bottom: -6px;
    left: 50%;
    margin-left: -6px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
