.container {
    max-width: 500px;
    width: 100%;
    padding: 50px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    color: #13353f;
}

.pageNotFoundIcon {
    display: inline-block;
}

@media only screen and (max-width: 415px) {
    .pageNotFoundIcon {
        width: 300px;
    }
}

.title {
    font-size: 26px;
    margin: 50px 0 30px 0;
    text-align: center;
    font-weight: bold;
}

.text {
    font-size: 16px;
    margin-bottom: 30px;
    text-align: center;
}

.buttonWrapper {
    position: relative;
}

.buttonWrapper button {
    padding: 0 35px 0 35px;
}

.slideArrowRightIcon {
    width: 15px;
    height: 15px;
    position: relative;
    right: 10px;
}
