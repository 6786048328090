.patientBlockTitle {
    margin-bottom: 20px;
    font-size: 22px;
    color: #13353f;
}

.patient {
    display: flex;
    padding: 20px 0;
    justify-content: flex-start;
    align-items: flex-start;
}

.patient:not(:last-child) {
    border-bottom: 1px solid rgba(19, 53, 63, 0.15);
}

.onlineRegistrationPatient {
    border-bottom: none;
}

.patientPhoto {
}

.patientInfo {
    display: flex;
    flex-direction: column;
    width: 200px;
    padding: 0 15px;
    font-size: 12px;
}

.patientName {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.patientBirthday {
}

.patientEmail {
}

.switchContainer {
    width: 48px;
    height: 25px;
    background-color: #dde4e5;
    border: 2px solid;
    border-radius: 15px;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    display: flex;
    cursor: pointer;
}

.switchInactive.switchContainer:hover {
    background-color: rgba(180, 180, 180, 0.8);
    border-color: rgba(180, 180, 180, 0.8);
}

.switchActive {
    justify-content: flex-end;
    background: #0097b3;
    border-color: #0097b3;
    transition: all 0.5s;
}

.switchInactive {
    justify-content: flex-start;
    background-color: #dde4e5;
    border-color: #dde4e5;
    transition: all 0.3s;
}

.switch::after {
    display: inline-block;
    width: 22px;
    height: 22px;
    content: '';
    background-color: #fff;
    border-radius: 15px;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.addPatientButton {
    margin-top: 25px;
}

.changeRegistrationModeBtn {
    margin-top: 10px;
    font-weight: bold;
    font-size: 17px;
}
