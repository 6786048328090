.modal {
    padding: 0 !important;
    max-width: 1440px;
    width: 1440px;
    background: transparent;
    margin-top: 90px;
}

@media only screen and (max-width: 1365px) {
    .modal {
        width: 100%;
        height: 100%;
        padding: 0 !important;
        min-width: auto;
        margin-top: 0 !important;
    }
}

.modal > button {
    top: 20px;
}

.modal form {
    background: #ffffff;
    margin-bottom: 40px;
}

@media only screen and (max-width: 1365px) {
    .modal form {
        margin-bottom: 0;
        height: 100vh;
    }

    .modal > button {
        display: none;
    }
}

.cities {
    padding: 58px 0 24px;
    display: flex;
    flex-wrap: wrap;
}

@media only screen and (max-width: 1024px) {
    .cities {
        padding: 30px 0 24px;
    }
}

@media only screen and (max-width: 480px) {
    .cities {
        padding: 20px 0 24px;
    }
}

.searchCity {
    padding: 24px 0;
}

@media only screen and (max-width: 1365px) {
    .searchCity {
        padding: 14px 0;
        display: flex;
    }
}

@media only screen and (max-width: 480px) {
    .searchCity {
        padding: 0;
    }
}

.changeCitySearch {
    display: flex;
    margin: 0 48px;
}

@media only screen and (max-width: 1365px) {
    .changeCitySearch {
        margin: 0 30px;
        align-items: center;
    }
}

@media only screen and (max-width: 700px) {
    .changeCitySearch {
        margin-right: 135px;
    }
}

@media only screen and (max-width: 480px) {
    .changeCitySearch {
        margin: 0 14px;
        width: 100%;
    }
}

.changeCitySearchInput {
    min-width: 590px;
    padding: 12px 25px;
    border: 2px solid #0097b3;
    border-radius: 86px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    line-height: 150%;
    color: #15353e;
    position: relative;
    font-family: sans-serif;
}

@media only screen and (max-width: 1365px) {
    .changeCitySearchInput {
        border: none;
        width: 330px;
        font-size: 13px;
        margin-left: 35px;
        min-width: auto;
    }

    .changeCitySearchInput::placeholder {
        color: #cccccc;
        font-weight: normal;
        padding-left: 3px;
    }
}

@media only screen and (max-width: 480px) {
    .changeCitySearchInput {
        width: 100%;
        margin-left: 0;
    }
}

@media only screen and (max-width: 600px) {
    .modal input[type='text']:focus,
    .modal input[type='number']:focus,
    .modal textarea:focus {
        font-size: 16px;
    }
}

.clearSearchStringIcon {
    display: none;
}

@media only screen and (max-width: 1365px) {
    .clearSearchStringIcon {
        display: inline-block;
        margin-right: 20px;
    }
}

.changeCityTabContent {
    background-color: #e5e5e5;
    padding: 24px 48px 50px 48px;
}

@media only screen and (max-width: 1365px) {
    .changeCityTabContent {
        background-color: #fff;
        padding: 24px 0 50px 30px;
    }
}

@media only screen and (max-width: 480px) {
    .changeCityTabContent {
        padding: 24px 0 10px 10px;
    }
}

@media only screen and (max-width: 480px) {
    .changeCityTabContent:not(:root:root) {
        padding-bottom: 100px;
    }
}

.closePopup {
    background-size: 100% 100%;
    width: 23px;
    height: 23px;
    position: absolute;
    right: 53px;
    top: 36px;
    cursor: pointer;
}

.tabCountries {
    width: 50%;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
    border: 2px solid #0097b3;
    border-radius: 0 26px 26px 0;
    background: #fff;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: cubic-bezier(0.27, 0.67, 0.41, 1.06);
    -o-transition-timing-function: cubic-bezier(0.27, 0.67, 0.41, 1.06);
    transition-timing-function: cubic-bezier(0.27, 0.67, 0.41, 1.06);
}

.cityNavButtons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

@media only screen and (max-width: 1365px) {
    .cityNavButtons {
        flex-wrap: nowrap;
        overflow-x: auto;
    }
}

.cityNav a {
    display: block;
    width: 48px;
    height: 48px;
    background: #fff;
    padding: 12px 18px;
    font-size: 16px;
    color: #0097b3;
    font-weight: 700;
    font-family: Open Sans, sans-serif;
    text-align: center;
    margin: 0 12px 12px 0;
    border-radius: 4px;
}

@media only screen and (max-width: 1365px) {
    .cityNav a {
        background: #eaf6f8;
        width: 42px;
        height: 36px;
        font-size: 12px;
        color: #13353f;
        line-height: 1;
    }
}

.cityNav a:hover {
    background-color: #ff6a13;
    color: #fff;
}

.selectBasketCityItem {
    display: block;
    font-size: 17px;
    font-weight: 600;
    color: #0097b3;
    font-family: Open Sans, sans-serif;
    /* При изменении ширины необходимо изменить условие разбиения на колонки в ChangeCityForm.sortForColumns */
    width: 25%;
    padding-bottom: 15px;
}

@media only screen and (max-width: 1024px) {
    .selectBasketCityItem {
        /* При изменении ширины необходимо изменить условие разбиения на колонки в ChangeCityForm.sortForColumns */
        width: 33%;
        font-size: 13px;
    }
}

@media only screen and (max-width: 480px) {
    .selectBasketCityItem {
        /* При изменении ширины необходимо изменить условие разбиения на колонки в ChangeCityForm.sortForColumns */
        width: 50%;
        padding-bottom: 10px;
        padding-right: 5px;
    }
}

.letterTab.active {
    background-color: #ff6a13;
    color: #fff;
}

.searchNoResult {
    font-weight: bold;
}

.searchResult {
    background: #e5e5e5;
    min-height: 836px;
    padding: 18px 46px;
}

@media only screen and (max-width: 1365px) {
    .searchResult {
        background: #ffffff;
        padding: 18px 30px;
    }
}

.searchResult ul {
    width: 580px;
}

@media only screen and (max-width: 1365px) {
    .searchResult ul {
        width: 330px;
    }
}

@media only screen and (max-width: 480px) {
    .searchResult ul {
        width: 100%;
    }
}

.searchResult .selectBasketCityItem {
    display: block;
    font-size: 16px;
    font-weight: 400;
    color: #13353f;
    font-family: 'PT Sans', sans-serif;
    padding-bottom: 15px;
    width: 90%;
}

.searchResult .selectBasketCityItem strong {
    font-weight: bold;
    color: #000000;
}

@media only screen and (max-width: 1365px) {
    .searchResult .selectBasketCityItem {
        font-family: 'Gilroy ExtraBold', sans-serif;
        font-weight: 800;
        font-size: 14px;
        color: #5c7d88;
    }
}

@media only screen and (max-width: 480px) {
    .searchResult {
        padding: 0 14px;
    }
}

.searchResult ul li {
    color: #13353f;
    padding: 18px 0;
    position: relative;
    border-bottom: 1px solid rgba(19, 53, 63, 0.2);
    cursor: pointer;
}

@media only screen and (max-width: 480px) {
    .searchResult ul li:first-child {
        border-top: 1px solid rgba(19, 53, 63, 0.2);
    }
}

.searchResult ul li:after {
    content: ' ';
    display: block;
    width: 28px;
    height: 20px;
    background-image: url(assets/searchArrow.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    bottom: 17px;
    right: 4px;
    pointer-events: none;
}

@media only screen and (max-width: 1365px) {
    .searchResult ul li:after {
        width: 25px;
        height: 14px;
    }
}

.searchResult .selectBasketCityItem {
    padding-bottom: 0;
}

.searchStringIcon {
    top: 10px;
    position: relative;
    right: 40px;
}

@media only screen and (max-width: 1365px) {
    .searchStringIcon {
        position: static;
        transform: scale(-1, 1);
        margin-top: 10px;
    }

    .searchStringIcon svg {
        width: 19px;
        height: 24px;
    }
}

.searchCloseMobile {
    display: none;
    padding-top: 13px;
}

@media only screen and (max-width: 1365px) {
    .searchCloseMobile {
        display: inline;
        padding-top: 0 !important;
    }
}
