.card {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    background-color: white;
}

.cardBody {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 26px;
}

.cardHeader {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cardHeaderTitle {
    font-family: 'Gilroy ExtraBold', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 24px;
    inline-size: 130px;
}

.cardHeaderMessage {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
}

.cardPercent {
    font-family: 'Gilroy ExtraBold', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 80px;
    line-height: 80px;
}

@media only screen and (max-width: 768px) {
    .cardHeaderTitle {
        font-size: 18px;
    }

    .cardHeaderMessage {
        font-size: 13px;
    }

    .cardPercent {
        font-size: 64px;
    }
}
