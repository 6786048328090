.footer {
    background-color: #f2f9fa;
    border-top: 1px solid #ffffff;
}

.footer .container {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: stretch;
}

.footer .container .item {
    flex: 1 1 auto;
    padding: 24px;
}

@media only screen and (max-width: 1024px) {
    .footer .container .item:first-child {
        flex-wrap: wrap;
    }
}

.item:not(:last-child) {
    border-right: 1px solid #fff;
}

.footer .container .item .link {
    font-family: PT Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 22px;
    color: #1797b1;
}

@media only screen and (max-width: 679px) {
    .communications .item:first-child {
        flex-wrap: wrap;
        white-space: nowrap;
    }
}

@media only screen and (max-width: 679px) {
    .footer .container .item .link {
        width: 33.5%;
        display: inline-flex;
    }
}

.footer .container .item a:hover {
    cursor: pointer;
    color: #5c7d88;
}

.footer .container .item .communication {
    padding-bottom: 10px;
}

.footer .container .item .link:not(:last-child) {
    margin-bottom: 20px;
}

.footer .container .item .socialLink:not(:last-child) {
    margin-right: 20px;
}

.footer .container .item .socialLink a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    border-radius: 50%;
}

.footer .container .item .socialLink a:hover {
    background-image: none;
}

.facebook,
.ok,
.vk,
.youtube,
.instagram,
.telegram {
    background-color: rgba(23, 151, 177, 0.15);
}

.footer .container .item .socialLink .facebook:hover {
    background-color: #3f5a95;
}

.footer .container .item .socialLink .facebook svg {
    width: 20px;
    height: 26px;
    fill: #fff;
}

.footer .container .item .socialLink .ok:hover {
    background-color: #f68634;
}

.footer .container .item .socialLink .ok svg {
    width: 20px;
    height: 17px;
    fill: #fff;
}

.footer .container .item .socialLink .vk:hover {
    background-color: #4c6c91;
}

.footer .container .item .socialLink .vk svg {
    width: 20px;
    height: 17px;
    fill: #fff;
}

.footer .container .item .socialLink .youtube:hover {
    background-color: #f90019;
}

.footer .container .item .socialLink .youtube svg {
    width: 32px;
    height: 25px;
    fill: #fff;
}

.footer .container .item .socialLink .instagram:hover {
    background-image: radial-gradient(circle at 30% 107%, #fdf497 0, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
}

.footer .container .item .socialLink .instagram svg {
    width: 25px;
    height: 20px;
    fill: #fff;
}

.footer .container .item .socialLink .telegram svg {
    width: 20px;
    height: 17px;
    fill: #fff;
}

.footer .container .item .socialLink .telegram:hover {
    background: rgba(93, 144, 194, 1);
}

@media only screen and (max-width: 767px) {
    .footer .container {
        flex-flow: column;
    }
}

.linksBlock {
    display: flex;
    flex-direction: column;
}

.socialLinks {
    display: flex;
    margin-top: 20px;
}

.applicationLinks {
    margin-top: 20px;
}

.downloadApp {

    background-color: transparent !important;
    box-shadow: inset 0 0 0 1.7px #08c3dc !important;
    transition: background-color 0.4s !important;
    cursor: pointer;
}

.downloadApp:active {
    background-color: #08c3dc !important;
}

.applicationLinks img:not(:first-child) {
    margin-left: 16px;
}

@media only screen and (max-width: 600px) {
    .applicationLinks img:not(:first-child) {
        margin-left: 10px;
    }
    .downloadApp {
        width: 100%;
    }

    .socialLinks {
        justify-content: space-between;
    }
}

.communications .item {
    border: none;
}

.communications {
    display: flex;
    flex: 1 1 auto;
}

.communications .item {
    border-right: 1px solid #fff;
}

@media only screen and (max-width: 1024px) {
    .communications,
    .communications div:last-child {
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (max-width: 768px) {
    .communications {
        border-top: 1px solid #fff;
    }

    .item:not(:first-child) {
        border-top: 1px solid #fff;
    }
}

@media only screen and (max-width: 767px) {
    .item {
        border-right: none !important;
    }
}
