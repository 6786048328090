.upButton {
    border: none;
    width: 100%;
    height: 40px;
    background-color: #f2f9fa;
    text-align: right;
    cursor: pointer;
    text-transform: none;
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.2;
    margin: 0;
    padding-right: 20px;
    border-bottom: 1px solid #ffffff;
}

.upButtonText {
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    color: #1797b1;
}

.upButtonText::before {
    content: '';
    display: inline-block;
    background-image: url(page/layout/assets/arrow-up.svg);
    background-size: cover;
    width: 8px;
    height: 11px;
    margin-right: 10px;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    position: relative;
    top: 1px;
}
