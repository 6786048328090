.container {
    display: flex;
}
.officeBlockTitle {
    margin-bottom: 20px;
    font-size: 22px;
    color: #13353f;
}

.office {
    display: flex;
    padding: 20px 0;
    justify-content: flex-start;
    align-items: flex-start;
}

.office:not(:last-child) {
    border-bottom: 1px solid rgba(19, 53, 63, 0.15);
}

.onlineRegistrationPatient {
    border-bottom: none;
}

.officePhoto {
}

.officeInfo {
    display: flex;
    flex-direction: column;
    width: 200px;
    padding: 0 15px;
    font-size: 12px;
}

.officeName {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.metroInfo {
    display: flex;
    align-items: center;
}

.metroContainer {
    display: flex;
    gap: 5px;
}

.metroIcon {
    width: 15px;
    height: 15px;
    display: inline-block;
    -webkit-mask: url('../assets/metro-icon.svg') no-repeat center;
    mask: url('../assets/metro-icon.svg') no-repeat center;
    -webkit-mask-size: contain;
    mask-size: contain;
    margin-bottom: 2px;
    background-color: currentColor;
}