.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 16px;
    line-height: 24px;
    min-height: 24px;
}

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.container.error .checkmark {
    border: 1px solid red;
    box-shadow: 0 0 0 0 red inset;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    transition: border 0.3s;
    background-color: #fff;
    border: 1px solid #0097b3;
    border-radius: 50%;
}

.container.error input:checked ~ .checkmark::after {
    background-color: red;
}

.container input:checked ~ .checkmark {
}

.content {
    font-weight: normal;
}

.container input:checked ~ .content {
    color: #0097b3;
}

.checkmark:after {
    content: '';
    position: absolute;
}

.container input:checked ~ .checkmark:after {
    display: block;
    transition: all 0.3s;
    transform: scale(1);
    opacity: 1;
}

.container .checkmark:after {
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    margin-top: -4px;
    margin-left: -4px;
    background-color: #0097b3;
    border-radius: 50%;
    transform: scale(0);
    opacity: 0;
}
