.contentContainer {
    padding: 20px 24px;
    border-top: 1px solid #e8eced;
}

.loaderContainer {
    display: flex;
    justify-content: center;
}

.patientActionsBlock {
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.actions {
    display: flex;
    justify-items: flex-end;
    flex-wrap: wrap;
    gap: 13px;
}

.actions > div {
    margin: 0;
}

.actions > button {
    margin: 10px;
}

.date {
    font-size: 12px;
    color: #5c7d88;
    margin-bottom: 7px;
}

.inz {
    font-weight: 900;
    font-family: 'Gilroy ExtraBold', sans-serif;
    font-size: 26px;
    color: #13353f;
    margin-bottom: 5px;
    line-height: 1;
}

.office {
    margin-top: 5px;
}

.resultsConsultationWarning {
    color: #aeb1b3;
    font-size: 14px;
}

.loginContainer {
    display: flex;
    align-items: center;
    padding: 12px 24px;
}

.loginContainer > p {
    font-family: 'Gilroy ExtraBold', sans-serif;
    font-size: 24px;
    line-height: 32px;
    padding-right: 10px;
}
