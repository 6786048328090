.input {
    margin-bottom: 20px;
}

.error {
    border: 1px solid #eb3223 !important;
}

.errorText {
    margin-top: 0;
}

.coupon {
    min-width: 145px;
}

.inapplicable {
    text-decoration: line-through;
}

.inapplicableHint {
    font-size: 14px;
    line-height: 21px;
    color: #eb3223;
    margin-top: 10px;
    text-decoration: none !important;
}

.wrapperForm {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 600;
}

.wrapperForm input {
    padding: 15px 50px 15px 15px;
    border-radius: 5px;
    width: 100%;
}

.wrapperForm input:focus {
    border: 1px solid #0097b3;
}

.applyButton {
    align-self: flex-end;
}

.applyButton + div {
    margin-top: 25px;
}

.appliedCoupons {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    background: #f4f9fa;
    color: #000000;
    font-size: 16px;
    line-height: 24px;
    padding: 10px;
}

.appliedCoupon {
    display: flex;
    justify-content: space-between;
}

.closeBtn svg {
    width: 18px;
    height: 18px;
    margin-left: 15px;
}

.closeBtn:hover svg path {
    stroke: #ff6a13;
}

.hint {
    margin-left: 10px;
}

.labelWrapper {
    display: flex;
    margin-bottom: 5px;
}
