.container {
    padding: 20px;
    margin-bottom: 10px;
}

.content {
    display: flex;
    justify-content: space-between;
}

.value {
    font-weight: bold;
}
