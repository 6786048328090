.button {
    font-family: 'Gilroy ExtraBold', sans-serif;
    color: #fff;
    background-color: #1797b1;
    height: 50px;
    border: 2px solid #1797b1;
    border-radius: 100px;
    font-size: 18px;
    line-height: 48px;
    transition: all 0.3s;
    padding: 0 30px 0 25px;
    cursor: pointer;
    outline: none;
    white-space: nowrap;
}

.button:hover {
    background-color: #21b4d0;
    border-color: #21b4d0;
}

.button:disabled {
    background-color: #afafaf;
    border-color: #afafaf;
    cursor: default;
}

@media only screen and (max-width: 400px) {
    .button {
        padding: 0 10px 0 10px;
    }
}

.icon {
    margin: 0 5px 0 0 !important;
}
