.container {
    background: #e5e5e5;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 100vh;
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
    .container {
        align-items: center;
    }
}

@media only screen and (max-width: 600px) {
    .container {
        background: #ffffff;
        padding: 15px;
    }
}

.info {
    display: flex;
    flex-direction: column;
    padding: 25px;
    width: 415px;
    background: #ffffff;
    margin-top: 50px;
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
    .info {
        margin-top: 0;
        margin-bottom: 130px;
    }
}

@media only screen and (max-width: 600px) {
    .info {
        padding: 0;
        width: auto;
        margin: 0;
    }
}

.description {
    font-size: 24px;
    font-weight: 800;
    line-height: 28px;
}

@media only screen and (max-width: 768px) {
    .description {
        font-size: 16px;
        font-weight: 800;
        line-height: 19px;
    }
}

@media only screen and (max-width: 600px) {
    .description {
        font-size: 20px;
        font-weight: 800;
        line-height: 26px;
    }
}
.detailedDescription {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-top: 20px;
}

@media only screen and (max-width: 768px) {
    .detailedDescription {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
    }
}

@media only screen and (max-width: 600px) {
    .detailedDescription {
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
    }
}
