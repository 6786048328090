.city {
    height: 60px;
    cursor: pointer;
    position: relative;
}

.city.open {
    background-color: #fff;
}

.cityLabelBlock {
    position: relative;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 24px;
    color: #adb1b4;
    padding: 15px 15px;
}

@media only screen and (max-width: 1023px) {
    .cityLabelBlock {
        padding-left: 12px;
        padding-right: 12px;
    }
}

@media only screen and (max-width: 430px) {
    .cityLabelBlock {
        justify-content: left;
    }
}

.city .cityChange {
    cursor: default;
    position: absolute;
    top: 60px;
    box-shadow: 0 5px 20px 5px rgba(92, 125, 136, 0.15);
    z-index: 1;
    padding: 28px 24px;
    background-color: #fff;
    right: 0;
    min-width: 300px;
}

@media only screen and (max-width: 470px) {
    .city .cityChange {
        right: auto;
    }
}

.city .cityChange p {
    margin-bottom: 22px;
}

.city .btn {
    height: 40px;
    border: 1px solid #0097b3;
    border-radius: 100px;
    background-color: #0097b3;
    font-size: 14px;
    line-height: 38px;
    transition: all 0.3s;
    padding: 0 15px;
    cursor: pointer;
}

@media only screen and (max-width: 1030px) {
    .city .btn {
        padding: 0 8px;
    }
}

.city .cityConfirmBtn {
    color: #fff;
}

.city .cityChangeBtn {
    background-color: transparent;
    color: #0097b3 !important;
    display: inline-block;
}

.city .cityChangeBtn:hover {
    background-color: #0097b3 !important;
    color: #fff !important;
}

.city .cityChange button:first-child {
    margin-right: 12px;
}

.city .citySmall {
    max-width: 250px;
    margin-top: 20px;
    font-size: 14px;
    line-height: 1.3;
    color: #adb1b4;
}

.cityName {
    padding-left: 4px;
    color: #ff6a13;
    line-height: 18px;
    white-space: nowrap;
}

@media only screen and (max-width: 1199px) {
    .cityName {
        max-width: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.icon {
    width: 16px;
    height: 20px;
    margin-right: 10px;
    fill: #ff6a13;
}

.cityLabelIcon {
    width: 16px;
    height: 20px;
    margin-right: 10px;
}

@media only screen and (max-width: 767px) {
    .cityLabelIcon {
        margin-right: 2px;
    }
}

.cityLabelTextArrow {
    border-style: solid;
    border-width: 4px 4px 0;
    border-color: #cbd0d1 transparent transparent;
    margin-left: 5px;
}

.cityLabelTextArrow.open {
    transform: rotate(180deg);
}

@media only screen and (max-width: 1280px) {
    .cityLabelText {
        display: none;
    }
}
