.container {
    height: 60px;
    display: flex;
    justify-content: space-between;
    background-color: #f2f6f7;
}

@media only screen and (max-width: 767px) {
    .container {
        height: 42px;
    }
}

.row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.controls {
    display: flex;
    align-items: center;
}

.controls > * {
    margin-left: 20px;
    margin-right: 20px;
}

.controls > div {
    display: flex;
}

@media only screen and (max-width: 430px) {
    .controls {
        width: 100%;
        justify-content: space-between;
    }
}

@media only screen and (max-width: 1120px) {
    .controls > * {
        margin-left: 5px;
        margin-right: 5px;
    }
}

@media only screen and (max-width: 767px) {
    .controls > * {
        margin-left: 0 !important;
    }
}

.controls .buttonLink {
    margin-right: 10px;
}

.controls .buttonLink {
    color: #13353f;
    transition: color 0.3s;
}

.controls .buttonLink:hover,
.controls button:last-child:hover {
    color: #1797b1;
}

.controls .buttonLink + button,
.controls button:last-child,
.controls .buttonLink + button:hover {
    color: #000000;
}

@media only screen and (max-width: 1120px) {
    .buttonLink {
        max-width: 70px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.loginIcon {
    width: 10px;
    height: 13px;
    display: flex;
    align-self: center;
    margin: 0 6px 2px 15px;
}
