.hint {
    width: 20px;
    min-width: 20px;
    height: 20px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    background-color: #0096a9;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    font-family: 'PT Sans', sans-serif;
    display: inline-block;
    z-index: 1;
}

.tooltip {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.12);
    border-radius: unset;
    color: #13353f;
    font-family: 'PT Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.33333;
    background-color: white;
    opacity: 1 !important;
    max-width: 240px;
    text-align: start;
    z-index: 2;
}
