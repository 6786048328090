.title {
    font-size: 48px;
    margin: 30px 0;
    line-height: 1;
    padding-left: 20px;
}

@media only screen and (max-width: 1024px) {
    .title {
        padding-left: 20px;
    }
}
