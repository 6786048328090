.field {
    margin-bottom: 30px;
}

.field > label {
    font-family: 'PT Sans', sans-serif;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 6px;
}

.field .tip {
    margin-left: 10px;
}
