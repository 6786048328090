.button {
    color: #1797b1;
    background-color: #fff;
}

.button:hover {
    color: #fff;
    background-color: #1797b1;
    border-color: #1797b1;
}

.button:disabled {
    color: #1797b1;
    background-color: #afafaf;
    border-color: #afafaf;
}

.active {
    background: #1797b1;
    color: #ffffff;
}
