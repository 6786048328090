.container {
    display: flex;
    justify-content: flex-start;
}

.container .searchInput {
    width: calc(100% - 68px);
    height: 68px;
    border: none;
    padding: 0;
}

.container .searchInput::placeholder {
    font-weight: bold;
    opacity: 0.5;
    font-size: 14px;
}

.container .searchBtn {
    width: 68px;
    height: 68px;
    border: none;
    background: transparent;
    cursor: pointer;
}

.container .searchBtn .searchBtnIcon {
    width: 18px;
    height: 18px;
    stroke: #15353e;
}

.container .searchBtn:hover .searchBtnIcon {
    stroke: #0097b3;
}
