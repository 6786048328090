.container {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 12px;
    width: 160px;
    border-radius: 12px;
    border: 1px solid rgba(19, 53, 63, 0.17);
    margin-right: 16px;
    margin-top: 16px;
    justify-content: space-between;
    height: 104px;
    position: relative;
}

@media only screen and (max-width: 820px) {
    .container {
        width: 235px;
    }
}

@media only screen and (max-width: 768px) {
    .container {
        width: 49%;
        margin-right: 0;
    }
}

@media only screen and (max-width: 480px) {
    .container {
        width: 48%;
    }
}

.container:hover {
    background: #f2f9fa;
    cursor: pointer;
}

.top {
    display: flex;
    justify-content: space-between;
}

.top .icon {
    margin: 0;
}

.newFeature {
    font-family: PT Sans, sans-serif;
    position: relative;
    bottom: 5px;
    left: 5px;
    padding: 0 8px;
    border-radius: 50px;
    background: #ff6a13;
    color: #ffffff;
    font-size: 12px;
    line-height: 18px;
}

.text {
    font-family: 'PT Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    color: #0097b3;
}

.container.loading {
    cursor: default;
    opacity: 0.5;
    background: #f5f5f5;
}

.loading:before {
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: -50%;
    z-index: 2;
    display: block;
    content: '';
    width: 90%;
    height: 100%;
    background: linear-gradient(to right, rgba(154, 152, 152, 0) 0%, rgba(255, 255, 255) 100%);
    transform: skewX(0deg);
    animation: loading 1s linear infinite;
}

@keyframes loading {
    to {
        left: 50%;
    }
}
