.container {
    height: 80px;
    margin-left: 60px;
    margin-right: 10px;
    font-size: 14px;
    line-height: 28px;
    font-weight: 700;
    color: #000;
    cursor: pointer;
    transition: color 0.3s;
}

.row {
    display: flex;
    justify-content: space-between;
}

.row.center {
    align-items: center;
}

.iconBlock {
    position: relative;
    -webkit-transition: -webkit-transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.icon {
    width: 40px;
    height: 40px;
    display: block;
    fill: #0097b3;
    margin-right: 16px;
    -webkit-transition: fill 0.3s;
    -o-transition: fill 0.3s;
    transition: fill 0.3s;
}

.count {
    position: absolute;
    top: -8px;
    right: 12px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #0097b3;
    text-align: center;
    font-size: 12px;
    line-height: 24px;
    color: #fff;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
