.orderConfirmationContainer {
    background: #f7f7f7;
    padding: 50px 0;
}

@media only screen and (max-width: 920px) {
    .orderConfirmationContainer {
        padding: 20px 15px;
    }
}

.orderConfirmation {
    max-width: 900px;
    margin: 0 auto;
}

.orderConfirmationHeader {
    display: flex;
    flex-direction: column;
}

.linkToBasket {
    display: flex;
    justify-content: flex-start;
}

.linkToBasket a {
    font-weight: 600;
    font-size: 12px;
    color: #0097b3;
    margin-left: 10px;
}

.linkToBasket a:hover {
    color: #06859d;
    transition: all 0.3s;
}

.pageTitle h1 {
    font-family: 'Gilroy ExtraBold', sans-serif;
    margin-top: 30px;
    font-weight: 800;
    font-size: 28px;
    color: #13353f;
}

@media only screen and (max-width: 480px) {
    .pageTitle h1 {
        font-size: 15px;
        line-height: 20px;
    }
}

.orderConfirmationBlock {
    display: flex;
    background: #f7f7f7;
    justify-content: center;
}

@media only screen and (max-width: 768px) {
    .orderConfirmationBlock {
        flex-direction: column;
    }
}

.leftColumn {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-right: 20px;
}

@media only screen and (max-width: 768px) {
    .leftColumn {
        width: 100%;
    }
}

.rightColumn {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: 20px;
}

@media only screen and (max-width: 768px) {
    .rightColumn {
        width: 100%;
        margin-left: 0;
    }
}

.leftColumn > div,
.rightColumn > div {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 60px 45px;
    margin-top: 30px;
}

@media only screen and (max-width: 768px) {
    .leftColumn > div,
    .rightColumn > div {
        padding: 25px 25px;
        margin-top: 15px;
    }
}

.orderContentsLink {
    color: #0097b3;
    cursor: pointer;
    transition: all 0.3s;
    border-bottom: 1px dashed #0097b3;
}

.loyaltyError {
    display: flex;
    flex-direction: column;
    background-color: transparent !important;
    padding: 0 !important;
    margin-top: 30px;
    order: -1;
}

.loyaltyErrorText {
    margin: 0;
}

.loyaltyErrorText svg {
    width: 30px;
    height: 25px;
}
