.card {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.message {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
}

.percent {
    font-family: 'Gilroy ExtraBold', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    color: #0097b3;
}
