.container {
    background: #e5e5e5;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
}

@media only screen and (max-width: 540px) {
    .container {
        padding: 15px;
    }
}

.card {
    width: 510px;
    background: #ffffff;
    padding: 50px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.inner {
    text-align: center;
}

.inner button {
    margin-top: 20px;
}

@media only screen and (max-width: 540px) {
    .card {
        background: transparent;
        justify-content: flex-start;
        padding: 0;
        margin: 0;
    }
    .inner {
        padding: 15px;
        background: #ffffff;
        margin-top: 30px;
    }
}

.info {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    padding: 35px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    background: #00a9bf;
    border-radius: 10px;
    color: #ffffff;
}

@media only screen and (min-width: 541px) and (max-width: 768px) {
    .info {
        padding: 20px;
    }
}

@media only screen and (max-width: 540px) {
    .info {
        padding: 10px;
    }
}

.info::after {
    content: '';
    margin: 0 auto;
    top: 42px;
    width: 7px;
    position: relative;
    border-style: solid;
    border-width: 8px 8px 0;
    border-color: #00a9bf transparent transparent;
}

@media only screen and (min-width: 541px) and (max-width: 768px) {
    .info::after {
        top: 27px;
    }
}

@media only screen and (max-width: 540px) {
    .info::after {
        top: 17px;
    }
}

.code {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 26px;
    text-align: left;
    border-bottom: 2px solid #13353f;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-top: 30px;
    background: #ffffff;
}

.code .labelError {
    width: 165px;
}

@media only screen and (max-width: 768px) {
    .code {
        flex-direction: column;
    }
}

@media only screen and (max-width: 540px) {
    .code {
        justify-content: center;
        border-width: 3px;
    }
    .code .labelCode {
        display: none;
    }
    .code .labelError {
        font-size: 10px;
        width: auto;
    }
}

.code label {
    width: 100px;
}

@media only screen and (max-width: 768px) {
    .code label {
        align-self: flex-start;
    }

    .code .labelError {
        width: auto;
    }
}

.code input {
    border: none;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 26px;
    letter-spacing: 10px;
    text-align: left;
    width: 170px;
    margin-right: 50px;
}

@media only screen and (max-width: 768px) {
    .code input {
        margin: 0;
    }
}

@media only screen and (max-width: 540px) {
    .code input {
        font-size: 30px;
        width: 210px;
    }
}

.code input::placeholder {
    border: none;
}

.notice {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-top: 20px;
}

@media only screen and (max-width: 540px) {
    .notice {
        margin: 0;
        background: #ffffff;
        padding: 15px;
    }
}

.notice span {
    font-weight: 700;
}

.error,
.error input {
    color: #ff6a13 !important;
}

.sendCode {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #0097b3;
    cursor: pointer;
}
