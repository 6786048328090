.container {
    background: #e5e5e5;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
}

@media only screen and (max-width: 540px) {
    .container {
        padding: 15px;
    }
}

.card {
    width: 510px;
    background: #ffffff;
    padding: 50px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 540px) {
    .card {
        background: transparent;
        padding: 0;
        margin: 0;
        width: auto;
        justify-content: flex-start;
    }
}

.info {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    padding: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    background: #00a9bf;
    border-radius: 10px;
    color: #ffffff;
}

@media only screen and (max-width: 540px) {
    .info {
        padding: 10px;
    }
}

.info::after {
    content: '';
    margin: 0 auto;
    top: 43px;
    width: 7px;
    position: relative;
    border-style: solid;
    border-width: 8px 8px 0;
    border-color: #00a9bf transparent transparent;
}

@media only screen and (max-width: 540px) {
    .info::after {
        top: 17px;
    }
}

.contacts {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    padding: 5px;
}

@media only screen and (max-width: 540px) {
    .contacts {
        margin-top: 30px;
        padding: 0;
    }
}

.contact {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

@media only screen and (max-width: 540px) {
    .contact {
        height: 70px;
        background: #ffffff;
        align-items: center;
        padding: 0 10px 0 30px;
        font-size: 20px;
        font-weight: 400;
    }
}

.container button {
    border: none;
    background: transparent;
    cursor: pointer;
}

.loaderContainer {
    display: flex;
    justify-content: center;
    padding: 20px 0;
}
.error {
    opacity: 0.5;
    cursor: no-drop !important;
}