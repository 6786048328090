.generalRow {
    width: 100%;
}

.generalRow .generalContent {
    margin-left: auto;
    margin-right: auto;
    max-width: 1600px;
}

@media only screen and (max-width: 480px) {
    .generalRow .generalContent h1 {
        font-size: 24px;
    }
}
