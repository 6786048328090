.selector {
    width: 38px;
    display: inline-block;
    font-family: 'Gilroy ExtraBold', sans-serif;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 2px;
    transition: all 0.5s;
    text-align: center;
    text-transform: uppercase;
    border: 1px solid #13353f;
    border-radius: 13px;
}

.selector:hover {
    background: #13353f;
}

.selector :global(.language-select__control),
.selector :global(.language-select__control--is-focused) {
    min-height: 26px;
    background-color: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
}

.selector :global(.language-select__menu) {
    border-radius: unset !important;
}

.selector :global(.language-select__menu-list) {
    padding: 0 !important;
}

.selector :global(.language-select__option) {
    cursor: pointer;
}

.selector :global(.language-select__option--is-selected) {
    background: #13353f;
    color: #fff;
}

.selector :global(.language-select__option--is-focused),
.selector :global(.language-select__option):active {
    background: #cecece;
    color: #13353f;
}

.selector :global(.language-select__option) {
}

.selector :global(.language-select__value-container) {
    padding: 0;
    justify-content: center;
}

.selector :global(.language-select__single-value) {
    color: #13353f;
    background-color: transparent;
}

.selector:hover :global(.language-select__single-value) {
    color: #fff;
}

.selector :global(.language-select__indicators) {
    display: none;
}
