.container {
    background: #e5e5e5;
    display: flex;
    justify-content: center;
    min-height: 100vh;
}

@media only screen and (max-width: 879px) {
    .container {
        padding: 0 15px 15px 15px;
    }
}

.cards {
    display: flex;
    margin-bottom: 50px;
    align-items: flex-start;
    gap: 25px;
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .cards {
        align-items: center;
        margin-bottom: 130px;
    }
}

@media only screen and (max-width: 600px) {
    .cards {
        flex-direction: column;
    }
}

.card {
    width: 415px;
    margin-top: 50px;
    background: #ffffff;
    padding: 25px;
    min-height: 490px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media only screen and (max-width: 879px) {
    .card {
        margin-top: 25px;
    }
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .card {
        width: 222px;
        height: 325px;
        padding: 15px 15px 25px 15px;
    }
}

@media only screen and (max-width: 600px) {
    .card {
        width: 100%;
        margin-top: 15px;
        min-height: auto;
        padding: 15px;
    }
}

.infoBlock {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.cashbackTypeIcon {
    align-self: flex-start;
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .infoBlock {
        flex-direction: column;
    }
    .cashbackTypeIcon {
        order: -1;
        align-self: flex-start;
        width: 32px;
        height: 32px;
    }
}

@media only screen and (max-width: 600px) {
    .cashbackTypeIcon {
        width: 25px;
        height: 25px;
    }
}

.infoBlockTop {
    display: flex;
    justify-content: space-between;
}

.info {
    font-family: Open Sans, sans-serif;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
}

.regularInfo {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.regularInfo ul {
    display: flex;
    gap: 15px;
    flex-direction: column;
}

.regularInfo ul ol::before {
    content: '';
    background-color: black;
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 7px;
    margin-bottom: 1px;
    margin-right: 10px;
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .info {
        margin-top: 10px;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
    }
}

@media only screen and (max-width: 879px) {
    .info p {
        font-family: Open Sans, sans-serif;
        font-size: 12px;
        line-height: 18px;
    }
}

.additionalInformation {
    font-weight: bold;
    margin: 20px 0 20px 0;
}

.title {
    font-size: 30px;
    font-weight: 800;
    margin-bottom: 20px;
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .title {
        font-size: 16px;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 600px) {
    .title {
        font-size: 18px;
    }
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .card:nth-of-type(odd) {
        margin-right: 10px;
    }
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .card:nth-of-type(even) {
        margin-left: 10px;
    }
}

@media only screen and (max-width: 600px) {
    .card:nth-of-type(odd) {
        margin-right: 0;
    }
    .card:nth-of-type(even) {
        margin-left: 0;
    }
}

.cards button {
    align-self: flex-end;
}

@media only screen and (max-width: 879px) {
    .cards button {
        align-self: flex-start;
        font-size: 13px;
        padding: 0 23px;
        line-height: 13px;
        height: 36px;
    }
}

@media only screen and (max-width: 600px) {
    .cards button {
        align-self: flex-end;
    }
}

.loaderContainer {
    padding: 20px 45%;
}

@media only screen and (max-width: 600px) {
    .loaderContainer {
        padding: 35vh 0 0 0;
        margin: 0 auto;
    }
}
