.button {
    font-family: 'PT Sans', sans-serif;
    color: #0097b3;
    background: none;
    border: none;
    border-radius: unset;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.71429;
    white-space: nowrap;
    transition: all 0.3s;
    height: auto;
}

.button:hover {
    color: #ff6a13;
    background: none;
}

.button:disabled {
    color: #afafaf;
    background: none;
}
