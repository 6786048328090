.container {
    background: #e5e5e5;
    display: flex;
    justify-content: center;
    height: calc(100vh - 100px);
    align-items: center;
}

@media only screen and (max-width: 879px) {
    .container {
        padding: 15px;
        min-height: calc(100vh - 70px);
    }
}

@media only screen and (max-width: 600px) {
    .container {
        align-items: flex-start;
        min-height: calc(100vh - 50px);
    }
}

.card {
    width: 415px;
    background: #ffffff;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 100px;
}

@media only screen and (max-width: 879px) {
    .card {
        margin-top: 25px;
    }
}

@media only screen and (max-width: 600px) {
    .card {
        width: 100%;
        margin-top: 0;
    }
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .card {
        width: 225px;
    }
}

.infoBlock {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.activationCashbackIcon {
    align-self: flex-start;
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .infoBlock {
        flex-direction: column;
    }
    .activationCashbackIcon {
        order: -1;
        align-self: flex-start;
    }
}

.info {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    max-width: 300px;
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .info {
        margin-top: 15px;
    }
}

.title {
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    text-align: left;
    margin-bottom: 10px;
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .title {
        font-size: 16px;
    }
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .card:nth-of-type(odd) {
        margin-right: 25px;
    }
}

.card:nth-of-type(even) {
    margin-left: 25px;
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .card:nth-of-type(even) {
        margin-left: 5px;
    }
}

@media only screen and (max-width: 600px) {
    .card:nth-of-type(odd) {
        margin-right: 0;
    }
    .card:nth-of-type(even) {
        margin-left: 0;
    }
}

.buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 800;
    line-height: 18px;
    text-align: center;
}

.buttons button:first-child {
    margin-right: 50px;
    background: none;
    border: none;
    color: #0097b3;
    width: 65px;
    padding: 0;
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .buttons {
        justify-content: space-between;
    }

    .buttons button:first-child {
        margin-right: 0;
    }

    .buttons button {
        width: 100px;
        height: 35px;
        font-size: 13px;
        font-weight: 800;
        line-height: 14px;
    }
}
