.container {
}

.row {
    display: flex;
    justify-content: space-between;
    height: 80px;
}

.row.center {
    align-items: center;
}

.row.start {
    justify-content: flex-start;
}

.middleRow {
    width: 100%;
    padding-left: 24px;
}

.logo {
    width: 240px;
    height: 42px;
    display: block;
}

.headerTitle {
    display: block;
    padding-left: 15px;
    margin-left: 15px;
    border-left: 1px solid #0097b3;
    color: #0097b3;
    font-family: 'Gilroy ExtraBold', sans-serif;
    text-transform: uppercase;
}

.communications {
    display: flex;
}

.communications .phone {
    margin-right: 32px;
}

@media only screen and (max-width: 924px) {
    .logo {
        width: 180px;
        height: 36px;
    }
}

@media (max-width: 1279px) {
    .headerTitle {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .communications {
        display: none;
    }
}
