.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
}

.container img {
	margin-bottom: 30px;
}

.message {
    font-family: 'PT Sans', sans-serif;
    font-weight: 900;
    font-size: 26px;
    line-height: 1.1;
    max-width: 600px;
    text-align: center;
    margin-bottom: 25px;
}