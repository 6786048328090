.input {
    font-weight: 400;
    line-height: 20px;
    min-height: 50px;
    padding-left: 15px;
    padding-right: 15px;
    transition: border-color 0.2s;
    border: 1px solid #e8eced;
    color: #13353f;
    font-size: 16px;
    outline: none;
    width: 100%;
    box-sizing: border-box;
    appearance: none;
}

.input:focus {
    border-color: #0096a9;
}

.input.error {
    border-color: red;
    color: red;
}

.error.input:-webkit-autofill {
    -webkit-text-fill-color: red;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input[type='number'] {
    appearance: none;
    -moz-appearance: textfield;
}
