.container {
    background: #e5e5e5;
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: flex-start;
}

@media only screen and (max-width: 768px) {
    .container {
        padding: 15px;
    }
}

.card {
    width: 415px;
    background: #ffffff;
    padding: 25px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 540px) {
    .card {
        padding: 25px 20px;
        margin: 0;
        width: auto;
        justify-content: flex-start;
    }
}

.title {
    font-size: 22px;
    font-weight: 800;
    line-height: 29px;
}

@media only screen and (max-width: 879px) {
    .title {
        font-size: 15px;
        line-height: 20px;
    }
}

.products {
    background: #ffffff;
    margin-top: 20px;
    border-bottom: 1px solid #cbd0d1;
    padding-bottom: 5px;
}

@media only screen and (max-width: 879px) {
    .products {
        padding: 0;
    }
}

.productItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

@media only screen and (max-width: 879px) {
    .productItem {
        margin-bottom: 10px;
    }
}

.productTitle {
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    color: #13353f;
}

@media only screen and (max-width: 879px) {
    .productTitle {
        font-size: 12px;
        line-height: 18px;
    }
}

.productDiscount,
.noticeCashback span {
    font-family: 'Gilroy ExtraBold', sans-serif;
    font-size: 20px;
    font-weight: 800;
    line-height: 26px;
    color: #0097b3;
}

@media only screen and (max-width: 879px) {
    .productDiscount {
        font-size: 14px;
        line-height: 18px;
    }
}

.selectedSolution .productDiscount {
    color: #ff6a13;
    opacity: 1;
}

.noticeCashback {
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    border-bottom: 1px solid #cbd0d1;
    padding-bottom: 20px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.noticeCashback div {
    display: inline;
}

.noticeSolution {
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    margin-top: 20px;
}

.noticeSolution span {
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    margin-top: 20px;
    color: #ff6a13;
}

@media only screen and (max-width: 879px) {
    .noticeCashback,
    .noticeSolution,
    .noticeSolution span {
        font-size: 12px;
        line-height: 18px;
    }

    .noticeCashback span {
        font-size: 14px;
        line-height: 18px;
    }
}

.confirmButton {
    font-size: 16px;
    margin-top: 50px;
    border-radius: 90px;
    align-self: flex-end;
    width: auto;
}

@media only screen and (max-width: 879px) {
    .confirmButton {
        font-size: 13px;
        line-height: 14px;
        width: auto;
        height: 36px;
    }
}

.loaderContainer {
    display: flex;
    justify-content: center;
    padding: 20px 0;
}
