.container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #5c7d88;
}

.link {
    font-family: 'Gilroy ExtraBold', sans-serif;
    display: inline-block;
    font-size: 18px;
    line-height: 28px;
    color: #5c7d88;
}

footer .link {
    font-family: PT Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 22px;
    color: #13353f;
}

.description {
    font-size: 12px;
    line-height: 16px;
    color: #aeb1b3;
}

footer .description {
    font-family: PT Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #aeb1b3;
}
