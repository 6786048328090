.container {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

.button {
    cursor: pointer;
    background: none;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    outline: 0 !important;
    border: none;
}

.button {
    border-bottom: 1px solid #f6f6f6;
}

.button:hover {
    background: #f6f6f6;
}

.button.selected {
    background: #f6f6f6;
}

.icon {
    width: 50px;
}

.content {
    margin-left: 20px;
    flex-grow: 1;
}

.title {
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    text-align: left;
}

.description {
    font-family: PT Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}
