.container {
    background: #e5e5e5;
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: flex-start;
}

@media only screen and (max-width: 768px) {
    .container {
        padding: 15px;
    }
}

.card {
    width: 415px;
    background: #ffffff;
    padding: 25px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .card {
        margin-top: 20vh;
    }
}

@media only screen and (max-width: 540px) {
    .card {
        padding: 25px 20px;
        margin: 0;
        width: 100%;
        justify-content: flex-start;
    }
}

.title {
    font-size: 22px;
    font-weight: 800;
    line-height: 29px;
}

@media only screen and (max-width: 600px) {
    .title {
        font-size: 15px;
        line-height: 20px;
    }
}

.products {
    background: #ffffff;
    margin-top: 20px;
    border-bottom: 1px solid #cbd0d1;
    padding-bottom: 5px;
}

@media only screen and (max-width: 879px) {
    .products {
        padding: 0;
    }
}

.productItem {
    font-family: Open Sans, sans-serif;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

@media only screen and (max-width: 879px) {
    .productItem {
        margin-bottom: 10px;
    }
}

.productTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    color: #13353f;
    max-width: 320px;
}

@media only screen and (max-width: 879px) {
    .productTitle {
        font-size: 12px;
        max-width: 280px;
    }
}

@media only screen and (max-width: 600px) {
    .productTitle {
        font-size: 11px;
        font-weight: 400;
        line-height: 16px;
        color: #000000;
        max-width: 270px;
    }
}

@media only screen and (max-width: 380px) {
    .productTitle {
        max-width: 250px;
    }
}

@media only screen and (max-width: 320px) {
    .productTitle {
        max-width: 200px;
    }
}

.productDiscount,
.noticeCashback span {
    font-family: 'Gilroy ExtraBold', sans-serif;
    font-size: 20px;
    font-weight: 800;
    line-height: 26px;
    color: #0097b3;
}

@media only screen and (max-width: 879px) {
    .productDiscount,
    .noticeCashback span {
        font-size: 14px;
    }
}

.noticeCashback {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    border-bottom: 1px solid #cbd0d1;
    padding-bottom: 20px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    font-family: Open Sans, sans-serif;
}

@media only screen and (max-width: 879px) {
    .noticeCashback {
        font-size: 11px;
        line-height: 17px;
        padding: 0;
        border: none;
    }
}

@media only screen and (max-width: 600px) {
    .noticeCashback {
        padding: 15px 0 20px 0;
    }
}

.noticeService {
    font-size: 16px;
    line-height: 26px;
    margin-top: 20px;
    font-family: Open Sans, sans-serif;
}

.noticeService > span > span {
    font-weight: 700;
}

@media only screen and (max-width: 879px) {
    .noticeService {
        font-size: 11px;
        line-height: 17px;
        padding: 15px 0 0 0;
        margin: 0;
    }
}

@media only screen and (max-width: 600px) {
    .noticeService {
        padding: 0;
    }
}

.noticeService span {
    font-size: 16px;
    line-height: 26px;
    margin-top: 20px;
}

@media only screen and (max-width: 879px) {
    .noticeService span {
        font-size: 11px;
        line-height: 17px;
        color: #000000;
    }
}

@media only screen and (min-width: 880px) {
    .noticeService {
        font-weight: 600;
    }
}
