.container {
    background: #e5e5e5;
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: flex-start;
}

@media only screen and (max-width: 879px) {
    .container {
        padding: 15px;
    }
}

.card {
    width: 515px;
    margin-top: 50px;
    background: #ffffff;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media only screen and (max-width: 879px) {
    .card {
        margin-top: 25px;
        width: 345px;
        padding: 25px;
    }
}

@media only screen and (max-width: 600px) {
    .card {
        width: 100%;
        margin-top: 0;
    }
}

.infoBlock {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.infoBlock img {
    margin-top: 10px;
}

@media only screen and (max-width: 879px) {
    .infoBlock img {
        margin-top: 0;
    }
}

.activatedCashbackIcon {
    align-self: flex-start;
}

.info {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    max-width: 300px;
}

.title {
    font-size: 44px;
    font-weight: 800;
    line-height: 48px;
    max-width: 245px;
    margin-bottom: 30px;
}

@media only screen and (max-width: 879px) {
    .title {
        font-size: 24px;
        font-weight: 800;
        line-height: 29px;
    }
}

.noticeCalculated {
    border: 2px solid #ff6a13;
    color: #ff6a13;
    padding: 20px 0;
    text-align: center;
    width: 100%;
    font-size: 18px;
    font-weight: 800;
    line-height: 21px;
}

.notice {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
}

.notice strong {
    font-weight: 700;
}

.notice a {
    color: #4db5c8;
    font-weight: 700;
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .notice {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
    }
}

.buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 800;
    line-height: 18px;
    text-align: center;
}

.buttons button:first-child {
    background: none;
    border: none;
    color: #0097b3;
    width: 145px;
    padding: 0;
    font-size: 16px;
    font-weight: normal;
    line-height: 26px;
}

@media only screen and (max-width: 879px) {
    .buttons button {
        font-size: 13px;
        font-weight: 800;
        line-height: 14px;
    }

    .buttons button:first-child {
        width: 110px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
    }

    .buttons button {
        width: 160px;
        height: 35px;
        padding: 0 15px 0 15px;
    }
}
