.orderContentsLinkShow {
    color: #0097b3;
    cursor: pointer;
    transition: all 0.3s;
    border-bottom: 1px dashed #0097b3;
}

.orderContentsLinkHide {
    color: #0097b3;
    cursor: pointer;
    transition: all 0.3s;
    border-bottom: 1px dashed #0097b3;
}

.orderContentsIcon {
    display: inline-block;
    font-size: 26px;
    position: relative;
    top: 5px;
    left: 6px;
    color: #0097b3;
}

.orderContents {
    border-top: 1px solid rgba(19, 53, 63, 0.15);
    margin-top: 20px;
}

.orderContentsShowing {
    overflow-y: auto;
    max-height: 1450px;
    transition: max-height 0.5s;
    padding-right: 5px;
}

.orderContentsHidden {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s;
}

.orderContentsItem {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(19, 53, 63, 0.15);
    padding: 20px 0;
}

.orderContentsItemTitle {
    font-weight: normal;
}

.orderContentsItemPrice {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    white-space: nowrap;
    margin-left: 5px;
    text-align: right;
}

.onePrice {
    color: rgba(19, 53, 63, 0.5);
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    margin-top: 4px;
    width: fit-content;
}

.priceWithDiscount {
    color: #b8c1c2;
    text-decoration: line-through;
}
