.cashbackTypesButtons {
    display: flex;
    justify-content: center;
    padding: 15px;
}

.cashbackTypesButtons button {
    border-width: 1px;
    font-size: 16px;
    line-height: 17px;
    border-radius: unset;
    padding: 10px;
}

@media only screen and (max-width: 880px) {
    .cashbackTypesButtons button {
        font-size: 13px;
    }
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .cashbackTypesButtons button {
        line-height: 13px;
    }
}

@media only screen and (max-width: 600px) {
    .cashbackTypesButtons button {
        line-height: 14px;
    }
}

.cashbackTypesButtons button:first-child {
    border-radius: 100px 0 0 100px;
}

.cashbackTypesButtons button:last-child {
    border-radius: 0 100px 100px 0;
}

:global(.navigationBarInner div:first-child button:first-child),
:global(.navigationBarInner div:first-child button:last-child) {
    font-size: 16px;
    line-height: 17px;
}

@media only screen and (max-width: 768px) {
    :global(.navigationBarInner div:first-child button:first-child),
    :global(.navigationBarInner div:first-child button:last-child) {
        font-size: 13px;
        line-height: 14px;
    }
}

@media only screen and (max-width: 391px) {
    .cashbackTypesButtons button {
        white-space: break-spaces;
        height: unset !important;
        font-family: 'Gilroy Regular', sans-serif;
        border-width: 1px;
    }
}
