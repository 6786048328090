.loader {
    width: 100px;
    height: 100px;
}

.loader:after {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid #0096a9;
    border-top-color: transparent;
    animation: spin 1s linear infinite;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-duration: 1000ms;
    content: '';
    display: inline-block;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
