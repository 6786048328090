.container {
    background: #e5e5e5;
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: flex-start;
}

@media only screen and (max-width: 540px) {
    .container {
        padding: 15px;
    }
}

.existsContacts {
    display: flex;
}

.card {
    width: 745px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.top {
    background: #ffffff;
    padding: 50px 50px 0 50px;
    min-height: 180px;
}

.bottom {
    padding: 0 50px 50px 50px;
    background: #ffffff;
}

@media only screen and (max-width: 768px) {
    .bottom {
        height: 168px;
    }
}

.existsContacts .bottom {
    margin-top: 15px;
}

@media only screen and (max-width: 768px) {
    .card {
        width: 340px;
        margin-top: 25px;
    }

    .top,
    .bottom {
        padding: 25px;
    }
}

@media only screen and (max-width: 540px) {
    .card {
        justify-content: flex-start;
        margin: 0;
        width: 100%;
    }
}

.inner {
    display: flex;
    align-items: flex-end;
}

@media only screen and (max-width: 768px) {
    .inner {
        flex-direction: column;
        align-items: flex-start;
    }
}

.inner input:disabled {
    background-color: white;
    color: #13353f;
    -webkit-text-fill-color: #13353f;
    opacity: 1;
}

.contacts {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    padding: 5px;
}

@media only screen and (max-width: 768px) {
    .contacts {
        margin-top: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }
}

@media only screen and (max-width: 540px) {
    .contacts {
        margin-top: 30px;
        padding: 0;
    }
}

.contact {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid #e8ebec;
    padding-bottom: 20px;
}

@media only screen and (max-width: 768px) {
    .contact {
        margin-bottom: 0;
    }

    .contact:last-child {
        padding-bottom: 0;
    }

    .contact:not(:first-child) {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 540px) {
    .contact {
        background: #ffffff;
        align-items: center;
        font-size: 20px;
        font-weight: 400;
    }
}

.contact button {
    border: none;
    background: transparent;
    cursor: pointer;
}

.contact:last-child {
    border: none;
}

.info {
    font-family: Open Sans, sans-serif;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    padding: 35px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    background: #00a9bf;
    border-radius: 10px;
    color: #ffffff;
    max-width: 350px;
    align-self: center;
    margin: 0 auto;
}

@media only screen and (min-width: 541px) and (max-width: 768px) {
    .info {
        padding: 15px 40px 10px 40px;
        font-size: 11px;
        font-weight: normal;
        line-height: 17px;
    }
}

@media only screen and (max-width: 540px) {
    .info {
        padding: 20px;
        line-height: 17px;
        font-size: 11px;
        font-weight: 600;
    }
}

.info::after {
    content: '';
    margin: 0 auto;
    top: 42px;
    width: 7px;
    position: relative;
    border-style: solid;
    border-width: 8px 8px 0;
    border-color: #00a9bf transparent transparent;
}

@media only screen and (min-width: 541px) and (max-width: 768px) {
    .info::after {
        top: 18px;
    }
}

@media only screen and (max-width: 540px) {
    .info::after {
        top: 27px;
    }
}

.phone {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    margin: 30px 40px 0 0;
    background: #ffffff;
    font-size: 20px;
    font-weight: 800;
    line-height: 26px;
    color: #13353f;
    border-bottom: 2px solid #13353f;
}

.error.phone {
    color: #ff6a13;
    border-bottom: 2px solid #ff6a13;
}

.valid.phone {
    color: #b9c3c6;
}

@media only screen and (max-width: 768px) {
    .phone {
        flex-direction: column;
        margin: 0 0 20px 0;
        width: 100%;
        padding-bottom: 0;
    }
}

@media only screen and (max-width: 540px) {
    .phone {
        justify-content: center;
        border-width: 3px;
    }
}

.phone label {
    width: 150px;
}

.existsContacts label {
    font-size: 14px;
    line-height: 20px;
}

@media only screen and (max-width: 768px) {
    .phone label {
        align-self: flex-start;
        font-size: 11px;
        font-weight: 600;
        line-height: 17px;
    }

    .phone .labelError {
        width: auto;
    }

    .existsContacts label {
        width: auto;
    }
}

.phone input {
    font-family: 'Gilroy ExtraBold', sans-serif;
    border: none;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 26px;
    text-align: left;
    width: 230px;
    margin-left: 50px;
}

@media only screen and (max-width: 768px) {
    .phone input {
        margin: 0;
        text-align: center;
    }
}

@media only screen and (max-width: 540px) {
    .phone input {
        width: 210px;
        font-size: 18px;
        font-weight: 800;
        line-height: 21px;
        text-align: center;
    }
}

.phone input::placeholder {
    border: none;
    color: #b9c3c6;
    -webkit-text-fill-color: #b9c3c6;
}

.sendCode {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #0097b3;
    cursor: pointer;
}

.notice {
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    position: relative;
    top: 30px;
}

.notice a {
    color: #4db5c8;
}

@media only screen and (min-width: 541px) and (max-width: 768px) {
    .notice {
        font-size: 11px;
        line-height: 16px;
    }

    .notice span {
        display: block;
    }
}

@media only screen and (max-width: 540px) {
    .notice {
        font-size: 11px;
        font-weight: 600;
        line-height: 17px;
    }
}

@media only screen and (max-width: 768px) {
    .inner button {
        align-self: flex-end;
        height: 36px;
        line-height: 13px;
        font-size: 13px;
    }
}
