.modal {
    padding: 0;
}

.modal > button {
    position: absolute;
    right: 50px;
    top: 25px;
}

@media only screen and (max-width: 600px) {
    .modal > button {
        right: 38px;
        top: 10px;
    }
}

.container {
    padding: 40px;
    display: flex;
    max-width: 480px;
    flex-direction: column;
}

@media only screen and (max-width: 600px) {
    .container {
        padding: 30px;
        width: 100%;
    }
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.title {
    font-family: 'Gilroy ExtraBold', sans-serif;
    font-weight: 800;
    font-size: 24px;
    line-height: 28px;
    color: #13353f;
    max-width: 370px;
}

@media only screen and (max-width: 600px) {
    .title {
        width: 241px;
        font-size: 16px;
        line-height: 19px;
    }
}

.content {
    margin: 30px 0;
}

.content span {
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
    .content {
        margin: 20px 0;
        font-size: 14px;
    }
}
