.pointsBalance {
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 0;
}

.pointsBalanceItems {
    display: flex;
    flex-direction: column;
    font-weight: 700;
}

.pointsBalanceItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.pointsBalanceTitle {
    font-size: 16px;
}

.pointsBalanceCount {
    color: #0097b3;
    white-space: nowrap;
}

.pointsBalanceNotice {
    width: 50%;
    font-size: 12px;
    color: #13353f;
    margin-bottom: 25px;
}

.pointsBalanceForm {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 600;
}

.pointsWriteOffBlock {
    display: flex;
    position: relative;
}

.pointsBalanceForm input {
    padding: 15px 50px 15px 15px;
    border-radius: 5px;
    width: 100%;
}

.pointsBalanceForm input:hover {
    border: 1px solid #0097b3;
}

.pointsBalanceFormError label {
    color: #ff6a13;
}

.pointsBalanceFormError input {
    border: 1px solid #ff6a13;
}

.pointsApplyIcon {
    width: 24px;
    height: 25px;
    align-self: flex-end;
    position: absolute;
    bottom: 37px;
    right: 10px;
    top: 10px;
}
