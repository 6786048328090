.container {
    width: 450px;
}

.description {
    font-family: 'PT Sans', sans-serif;
    color: #757575;
    font-weight: 100;
    margin-bottom: 50px;
}

@media only screen and (max-width: 768px) {
    .container {
        width: auto;
    }
}

.error {
    margin: 5px 0;
}

.container .searchFormBlock {
}

.container .searchFormBlock .searchFormBlockItem {
}

.container .buttonsBlock {
}

.container .buttonsBlock .button:first-child {
    margin-top: 0;
}

.container .buttonsBlock .button {
    width: 100%;
    margin-top: 30px;
}

.additionalFieldsNote {
    margin-bottom: 30px;
}
