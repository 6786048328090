.container {
    display: flex;
    flex-direction: column;
    padding: 0 32px 24px 32px;
    border: 1px solid #a1aeb2;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 20px 20px 0 20px;
    max-width: 885px;
    align-self: center;
}

@media only screen and (max-width: 879px) {
    .container {
        max-width: 580px;
    }
}

@media only screen and (max-width: 610px) {
    .container {
        max-width: none;
        margin: 20px;
    }
}

.title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #ff6a13;
    margin: 16px 0;
}

.notification p {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    color: #13353f;
}
