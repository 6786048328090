.container {
    display: block;
}

.topBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 30px;
    min-height: 100px;
}

.topBlock button {
    border: none;
    background: transparent;
    cursor: pointer;
    padding-left: 0;
}

@media only screen and (max-width: 879px) {
    .topBlock {
        min-height: 70px;
    }
}

@media only screen and (max-width: 600px) {
    .topBlock {
        min-height: 50px;
        padding: 0 15px 0 15px;
    }
}

.back {
}

.title {
    text-align: left;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
}

@media only screen and (max-width: 879px) {
    .title {
        font-size: 18px;
    }
}

@media only screen and (max-width: 600px) {
    .title {
        font-size: 16px;
        padding-right: 35px;
    }
}

.back + .title {
    margin: 0 auto;
}

.close {
}
