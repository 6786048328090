.title {
    font-size: 24px;
    line-height: 36px;
}

.form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 0;
    grid-row-gap: 0;
    width: 100%;
    gap: 23px;
    margin-bottom: 27px;
}

.payments {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 14px;
}

.paymentsText {
    font-size: var(--font-size-xs);
    color: var(--color-green-3);
    margin-bottom: 23px;
}

.actions {
    display: flex;
    flex-direction: column;
    gap: 23px;
}

.cardNumber {
    grid-area: 1 / 1 / 2 / 3;
}

.cardNumber.error {
    color: var(--color-red-1);
}

.cardNumber.error > div {
    border: 1px solid var(--color-red-1);
}

.expDate {
    grid-area: 2 / 1 / 3 / 2;
}

.expDate.error {
    color: var(--color-red-1);
}

.expDate.error > div {
    border: 1px solid var(--color-red-1);
}

.cvv {
    grid-area: 2 / 2 / 3 / 3;
}

.cvv.error {
    color: var(--color-red-1);
}

.cvv.error > div {
    border: 1px solid var(--color-red-1);
}

.email > label {
    color: rgb(21, 53, 62);
    font-family: 'Gilroy ExtraBold', sans-serif;
    font-size: 16px;
}

.email.error > label {
    color: var(--color-red-1);
}

.email input:focus {
    border-color: #e8eced;
}
.email.error input:focus {
    border-color: var(--color-red-1);
}

.inputEmail.error {
    border: 1px solid var(--color-red-1);
    color: var(--color-black-1);
}

.textError {
    color: var(--color-red-1);
}
