.switchContainer {
    min-width: 48px;
    width: 48px;
    height: 25px;
    background-color: #dde4e5;
    border: 2px solid;
    border-radius: 15px;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    display: flex;
    cursor: pointer;
}

.switchInactive.switchContainer:hover {
    background-color: rgba(180, 180, 180, 0.8);
    border-color: rgba(180, 180, 180, 0.8);
}

.switchActive {
    justify-content: flex-end;
    background: #0097b3;
    border-color: #0097b3;
    transition: all 0.5s;
}

.switchInactive {
    justify-content: flex-start;
    background-color: #dde4e5;
    border-color: #dde4e5;
    transition: all 0.3s;
}

.switch::after {
    display: inline-block;
    width: 22px;
    height: 22px;
    content: '';
    background-color: #fff;
    border-radius: 15px;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
