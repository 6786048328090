.container {
    background: #e5e5e5;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    align-items: flex-start;
}

@media only screen and (max-width: 879px) {
    .container {
        padding: 15px;
    }
}

.patients {
    display: flex;
    flex-wrap: wrap;
    width: 880px;
    margin-bottom: 50px;
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .patients {
        width: 520px;
    }
}

@media only screen and (max-width: 600px) {
    .patients {
        flex-direction: column;
    }
}

.card {
    width: 415px;
    margin-top: 50px;
    background: #ffffff;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Gilroy ExtraBold', sans-serif;
}

@media only screen and (max-width: 879px) {
    .card {
        margin-top: 25px;
    }
}

@media only screen and (max-width: 600px) {
    .card {
        width: 100%;
        margin-top: 15px;
    }
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .card {
        width: 222px;
        height: 252px;
    }
}

.card span {
    font-family: Open Sans, sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
}

.infoBlock {
    display: flex;
    justify-content: space-between;
}

.patientIcon {
    align-self: flex-start;
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .infoBlock {
        flex-direction: column;
    }
    .patientIcon {
        order: -1;
        align-self: flex-start;
    }
}

.info {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .info {
        margin-top: 10px;
    }
}

.title {
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    text-align: left;
    max-width: 200px;
    margin-bottom: 5px;
}

@media only screen and (max-width: 879px) {
    .title {
        font-size: 16px;
        line-height: 18px;
    }
}

.card:nth-of-type(odd) {
    margin-right: 25px;
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .card:nth-of-type(odd) {
        margin-right: 25px;
    }
}

.card:nth-of-type(even) {
    margin-left: 25px;
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .card:nth-of-type(even) {
        margin-left: 5px;
    }
}

@media only screen and (max-width: 600px) {
    .card:nth-of-type(odd) {
        margin-right: 0;
    }
    .card:nth-of-type(even) {
        margin-left: 0;
    }
}

.patients button {
    width: 125px;
    align-self: flex-end;
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .patients button {
        align-self: flex-start;
        font-size: 13px;
        height: 36px;
        width: 100px;
        line-height: 14px;
    }
}
