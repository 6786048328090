.container {
    background: #e5e5e5;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 879px) {
    .container {
        padding: 15px;
    }
}

.cards {
    display: flex;
    width: 880px;
    align-items: flex-start;
    margin-bottom: 50px;
}

.leftColumn {
    margin-right: 25px;
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .leftColumn {
        margin-right: 10px;
    }
}

@media only screen and (max-width: 600px) {
    .leftColumn {
        width: 100%;
        margin: 0;
    }
}

.rightColumn {
    margin-left: 25px;
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .rightColumn {
        margin-left: 10px;
    }
}

@media only screen and (max-width: 600px) {
    .rightColumn {
        width: 100%;
        margin: 15px 0 0 0;
    }
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .cards {
        width: 580px;
    }
}

@media only screen and (max-width: 600px) {
    .cards {
        flex-direction: column;
    }
}

.card {
    width: 415px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media only screen and (max-width: 879px) {
    .card {
        margin-top: 20px;
    }
    .card:first-child {
        margin-top: 0;
    }
}

@media only screen and (max-width: 600px) {
    .card {
        width: 100%;
        margin-top: 15px;
    }
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .card {
        width: 280px;
    }
}

.infoBlock {
    display: flex;
    flex-direction: column;
    padding: 25px;
    background: #ffffff;
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .infoBlock {
        flex-direction: column;
        padding: 15px;
    }
}

.info {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .info {
        margin-top: 15px;
    }
}

.title {
    font-size: 22px;
    font-weight: 800;
    line-height: 28px;
    max-width: 270px;
}

@media only screen and (max-width: 879px) {
    .title {
        font-size: 16px;
        line-height: 20px;
    }
}

.description {
    margin-top: 20px;
}
