.modal {
    max-width: 500px;
}

.modal p {
    margin-bottom: 30px;
}

.confirmButton {
    width: 100%;
}
