*,
:after,
:before {
    box-sizing: border-box;
    outline: 0;
}

ul {
    padding-left: 0;
    margin: 0;
    list-style: none;
}

ol,
ul {
    list-style: none;
}

a {
    color: inherit;
    font: inherit;
    text-decoration: none;
}

button,
input {
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.2;
    margin: 0;
}

.header {
    opacity: 0;
    -webkit-transition: opacity 0.5s ease-out;
    -o-transition: opacity 0.5s ease-out;
    transition: opacity 0.5s ease-out;
    border-bottom: solid 2px #e8eced;
}

.header.show {
    opacity: 1;
}

.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.row.rowCenter {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.row.rowStart {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.row.rowStart,
.row.rowWrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.city {
    position: relative;
    cursor: pointer;
}

.headerTop {
    height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #f2f6f7;
}

.headerTopRow {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    overflow: hidden;
}

.headerTopNav {
    margin-bottom: 0;
    padding-left: 0;
}

.headerTopNavItem {
    display: inline-block;
}

.headerTopNavLink {
    height: 60px;
    display: block;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 14px;
    line-height: 60px;
    color: #13353f;
    -webkit-transition: color 0.3s, -webkit-box-shadow 0.3s;
    transition: color 0.3s, -webkit-box-shadow 0.3s;
    -o-transition: color 0.3s, box-shadow 0.3s;
    transition: color 0.3s, box-shadow 0.3s;
    transition: color 0.3s, box-shadow 0.3s, -webkit-box-shadow 0.3s;
    cursor: pointer;
    -webkit-box-shadow: inset 0 -5px 0 0 transparent;
    box-shadow: inset 0 -5px 0 0 transparent;
}

.headerMiddle {
}

.headerMiddleRow {
    width: calc(100% - 80px);
    padding-left: 24px;
}

.headerMiddleLogo {
    width: 240px;
    height: 42px;
    display: block;
}

.headerPhone {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #5c7d88;
}

.headerPhoneLink {
    font-family: 'Gilroy ExtraBold', sans-serif;
    display: inline-block;
    margin-right: 32px;
    font-size: 18px;
    line-height: 28px;
    color: #5c7d88;
}

.headerPhoneDescription {
    position: absolute;
    left: 0;
    bottom: -20px;
    font-size: 12px;
    line-height: 28px;
    color: #aeb1b3;
}

.headerCart {
    height: 80px;
    margin-left: 60px;
    margin-right: 30px;
    font-size: 14px;
    line-height: 28px;
    font-weight: 700;
    color: #000;
    cursor: pointer;
    transition: color 0.3s;
}

.headerCartIconBlock {
    position: relative;
    -webkit-transition: -webkit-transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.headerCartIcon {
    width: 40px;
    height: 40px;
    display: block;
    fill: #0097b3;
    margin-right: 16px;
    -webkit-transition: fill 0.3s;
    -o-transition: fill 0.3s;
    transition: fill 0.3s;
}

.headerCartCount {
    position: absolute;
    top: -8px;
    right: 12px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #0097b3;
    text-align: center;
    font-size: 12px;
    line-height: 24px;
    color: #fff;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.headerMenuBtn {
    width: 80px;
    height: 80px;
    display: block;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    background-color: #ff6a13;
    border: none;
    cursor: pointer;
}

.ripple {
    position: relative;
    overflow: hidden;
    -webkit-transform: translatez(0);
    -ms-transform: translatez(0);
    transform: translatez(0);
}

.headerMenuBtnInner {
    position: relative;
    width: 20px;
    height: 16px;
    margin-right: auto;
    margin-left: auto;
}

.menuBtnLine {
    position: absolute;
    width: 100%;
    display: block;
    height: 2px;
    background-color: #fff;
    -webkit-transition: all 0.1s;
    -o-transition: all 0.1s;
    transition: all 0.1s;
}

.menuBtnLineT {
    top: 0;
}

.menuBtnLineM {
    top: 7px;
}

.menuBtnLineB {
    bottom: 0;
}

.headerBottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.navScroller {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
}

.headerNav {
    height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 24px;
}

.navScrollerNav {
    overflow-x: hidden;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    z-index: 1;
    -ms-flex-item-align: center;
    align-self: center;
    -ms-overflow-style: -ms-autohiding-scrollb;
}

.headerNavList {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: calc(100% - 310px);
    margin-bottom: 0;
    padding-left: 24px;
}

ul.navScrollerContent {
    margin: 0;
    padding: 0;
    list-style: none;
}

.navScrollerContent {
    /* display: -webkit-inline-box; */
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap;
    min-height: 32px;
    -webkit-transition: -webkit-transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.navScrollerItem {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    text-align: center;
}

.headerNavItem {
    padding-right: 40px;
}

.headerNavLink {
    color: #13353f;
    transition: color 0.3s;
    white-space: nowrap;
}

.headerNavGetResult,
.headerNavLink {
    font-size: 14px;
    font-family: 'Gilroy ExtraBold', sans-serif;
    line-height: 28px;
    text-transform: uppercase;
}

.iconAnalysisWrapper {
    width: 22px;
    height: 29px;
    stroke: inherit;
}

.headerNavGetResult .icon {
    position: relative;
    top: -3px;
    left: 0;
    width: 19px;
    height: 29px;
    stroke: #0097b3;
    stroke-width: 2px;
    fill: transparent;
}

.headerNavGetResult {
    position: relative;
    min-width: 310px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 24px;
    background-color: transparent;
    border: none;
    color: #0097b3;
    text-align: left;
    -webkit-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
}

.headerNavGetResult span {
    display: inline-block;
    padding-left: 12px;
    white-space: nowrap;
}

.headerSearch {
    position: relative;
    height: 70px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-top: 1px solid rgba(19, 53, 63, 0.5);
    border-bottom: 1px solid rgba(19, 53, 63, 0.5);
}

.headerSearchBox {
    width: 100%;
}

.searchBlock {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.searchBtn,
.searchInput {
    border: none;
    background-color: transparent;
}

.searchInput {
    display: block;
    width: calc(100% - 78px);
    height: 68px;
    font-size: 24px;
    line-height: 1;
    font-weight: 300;
    color: #13353f;
    padding: 19px 24px 21px;
}

.searchBtn {
    position: relative;
    width: 78px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
}

.searchBtn::before {
    content: '';
    position: absolute;
    top: 14px;
    left: 0;
    width: 2px;
    height: 40px;
    background-color: #cbd0d1;
}

.searchBtnIcon {
    width: 18px;
    height: 18px;
    stroke: #15353e;
    fill: transparent;
    -webkit-transition: stroke 0.2s;
    -o-transition: stroke 0.2s;
    transition: stroke 0.2s;
}
