.orderStatus {
    font-size: 16px;
    line-height: 1.5;
    color: #15353e;
}

.orderReady,
.notPaid,
.paidProgress,
.paid {
    color: #ff6a13;
}

.orderStatus button {
    position: relative;
    z-index: 5;
}

.phoneLink {
    color: #0097b3;
    white-space: nowrap;
}

.phoneLink:hover {
    color: #ff6a13;
}
