.container {
    display: flex;
    align-items: center;
}

.input {
    padding: 13px 15px 13px 15px;
    border: 1px solid #e9eced;
    flex: 1;
    min-width: 0;
    margin-bottom: 2px;
}

.input:focus {
    border-color: #1797b1;
}

.input {
    height: 50px;
    margin-left: 10px;
    color: #15353e;
}

.input::placeholder {
    font-family: PT Sans, sans-serif;
    font-size: 16px;
    color: #b8c1c2;
    line-height: 24px;
}

.value {
    display: flex;
    align-items: center;
}

:global(.simple-selectpicker__control) {
    min-width: 130px !important;
    max-width: 130px !important;
}

:global(.simple-selectpicker__menu-list) {
    width: 320px !important;
    border: 1px solid #e9eced;
}

:global(.simple-selectpicker__option) .code {
    width: 40px;
    color: #15353e;
    font-weight: bold;
}

:global(.simple-selectpicker__option) {
    color: #15353e !important;
}

:global(.simple-selectpicker__option):hover .code {
    color: #ffffff !important;
}

:global(.simple-selectpicker__option):hover .option {
    color: #ffffff !important;
}

:global(.simple-selectpicker__option--is-focused):hover .code {
    color: #ffffff;
}

.code {
    margin-right: 20px;
}

.option {
    color: #15353e;
    display: flex;
    align-items: center;
}

.flag {
    margin-right: 10px;
}

:global(.simple-selectpicker__single-value) .label {
    display: none;
}

:global(.simple-selectpicker__option--is-focused):hover .option {
    color: #ffffff !important;
}
