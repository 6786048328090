.container {
    background: #e5e5e5;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width: 879px) {
    .container {
        padding: 15px;
    }
}

.cards {
    display: flex;
    width: 880px;
    align-items: flex-start;
    margin-top: 15px;
    margin-bottom: 150px;
    height: 100vh;
}

.leftColumn {
    margin-right: 25px;
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .leftColumn {
        margin-right: 10px;
    }
}

@media only screen and (max-width: 600px) {
    .leftColumn {
        width: 100%;
        margin: 0;
    }
}

.rightColumn {
    margin-left: 25px;
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .rightColumn {
        margin-left: 10px;
    }
}

@media only screen and (max-width: 600px) {
    .rightColumn {
        width: 100%;
        margin: 15px 0 0 0;
    }
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .cards {
        width: 580px;
    }
}

@media only screen and (max-width: 600px) {
    .cards {
        flex-direction: column;
        width: 100%;
    }
}

.card {
    width: 415px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media only screen and (max-width: 879px) {
    .card {
        margin-top: 20px;
    }
    .card:first-child {
        margin-top: 0;
    }
}

@media only screen and (max-width: 600px) {
    .card {
        width: 100%;
        margin-top: 15px;
    }
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .card {
        width: 280px;
    }
}

.infoBlock {
    display: flex;
    flex-direction: column;
    padding: 25px 25px 0 25px;
    background: #ffffff;
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .infoBlock {
        flex-direction: column;
        padding: 15px 15px 0 15px;
    }
}

.info {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .info {
        margin-top: 15px;
    }
}

.topBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .topBlock {
    }
}

.title {
    font-size: 22px;
    font-weight: 800;
    line-height: 28px;
    max-width: 270px;
}

@media only screen and (max-width: 879px) {
    .title {
        font-size: 16px;
        line-height: 20px;
    }
}

.selectedSolution .title {
    color: #ff6a13;
}

.solutionExpire {
    font-family: Open Sans, sans-serif;
    padding: 5px 10px;
    background: #f6f6f6;
    text-align: center;
    margin-top: 20px;
    width: fit-content;
}

@media only screen and (max-width: 879px) {
    .solutionExpire {
        font-size: 12px;
        font-weight: 600;
        margin-top: 15px;
    }
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .solutionExpire {
        font-size: 11px;
    }
}

.products {
    padding: 15px 25px 0 25px;
    background: #ffffff;
}

@media only screen and (min-width: 601px) and (max-width: 879px) {
    .products {
        padding: 15px 15px 0 15px;
    }
}

.productItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

@media only screen and (max-width: 879px) {
    .productItem {
        margin-bottom: 5px;
    }
}

.productTitle {
    color: #0097b3;
    font-size: 16px;
    font-weight: 600;
    margin-right: 5px;
    cursor: pointer;
}

.productTitle:hover {
    color: #01bde0;
}

@media only screen and (max-width: 879px) {
    .productTitle {
        font-size: 12px;
    }
}

.productDiscount {
    color: #13353f;
    font-size: 20px;
    font-weight: 800;
    opacity: 0.2;
}

@media only screen and (max-width: 879px) {
    .productDiscount {
        font-size: 14px;
    }
}

.selectedSolution .productDiscount {
    color: #ff6a13;
    opacity: 1;
}

.notice {
    display: none;
    font-family: Open Sans, sans-serif;
}

.notice > div > div {
    display: inline-block;
}

@media only screen and (max-width: 600px) {
    .notice {
        margin-top: 15px;
        border-radius: 10px;
    }
    .notice > div > div > div {
        display: inline;
    }
}

.notice::before {
    content: '';
    margin: 0 auto;
    bottom: 21px;
    width: 7px;
    position: relative;
    border-style: solid;
    border-width: 8px 8px 0;
    transform: rotate(-180deg);
    border-color: #13353f transparent transparent;
}

@media only screen and (min-width: 600px) {
    .notice::before {
        bottom: 13px;
    }
}

.selectedSolution .notice {
    height: 60px;
    background: #13353f;
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s;
    flex-direction: column;
    padding-bottom: 8px;
}

.selectButton {
    display: flex;
    justify-content: center;
    background: #e5e5e5;
    padding-top: 50px;
}

@media only screen and (max-width: 600px) {
    .selectButton {
        padding-top: 20px;
    }
}

.selectButton button {
    height: 50px;
    width: 290px;
    border-radius: 90px;
    background: #ff6a13;
    margin-bottom: 20px;
    border: none;
}

@media only screen and (max-width: 600px) {
    .selectButton button {
        height: 36px;
        width: 100%;
        font-size: 13px;
        line-height: 14px;
        margin: 0 14px 24px 14px;
        padding: 0;
    }
}
